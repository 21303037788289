import styled from "styled-components";

export const ContainerUmidadeStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  width: 100%;
  max-height: 100%;
  justify-content: space-around;
  .icone-Calendario {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icone {
      display: flex;
      gap: 10px;
      h3 {
        font-size: 20px;
      }
      img {
        width: 25px;
      }
    }
    .calendario {
      display: flex;
      align-items: center;
      font-size: 50px;
      height: fit-content;
      max-height: 100%;
      position: relative;
      cursor: pointer;
      .conteinerIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 18px;
          position: absolute;
          top: 19px;
          left: 14px;
        }
      }
    }
  }
  .dadosDaUmidade {
    display: flex;
    max-width: 100%;
    align-items: end;
    gap: 8px;
    height: 120px;
    h3 {
      font-size: 30px;
      margin-top: 10px;
      span {
        font-size: 20px;
      }
    }
    p {
      font-size: 18px;
    }
    span {
      font-size: 18px;
    }
  }
`;

export const GraficoUmidadeStyled = styled.div`
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  height: 100%;
  background-color: var(--bgGrafico);
  max-height: 65%;
  .graficoUmidade {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 5px;
  }
`;
