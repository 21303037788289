import { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiConnect } from "../services/api";

export const LoginContext = createContext({});

export const LoginProvider = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("connectionsToken");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, []);

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    try {
      const response = await apiConnect.post("users/login/", data);
      sessionStorage.setItem("connectionsToken", response.data.access);
      toast.success("Login feito com Sucesso", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      navigate("/");
    } catch (error) {
      if (error.request.status === 401) {
        toast.error("Nome do Usuário invalido ou senha invalida", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warn("😅Algo de Errado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContext.Provider value={{ loading, setLoading, handleSubmitLogin }}>
      <Outlet />
    </LoginContext.Provider>
  );
};
