import styled, { css, keyframes } from "styled-components";

const slideInBlurredLeft = keyframes`
  0% {
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 100% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
`;

export const ConfigPivoStyled = styled.section`
  width: 96%;
  min-height: 100vh;
  max-height: 100%;
  h2 {
    margin: 20px 10px;
    font-size: 28px;
    color: rgba(204, 204, 204, 1);
  }
  ul {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
    width: 100%;
    list-style: none;
    padding-right: 10px;
    margin-top: 5px;
    .pivoConteiner {
      display: flex;
      padding-top: 80px;
      width: 100%;
      height: 85vh;
      position: relative;
      background-color: rgba(0, 0, 0, 0.41);
      border-radius: 10px;
      margin-bottom: 20px;
      max-height: 80px;
      border: 1px solid rgba(206, 206, 206, 0.2);
      overflow: hidden;
      gap: 20px;
      max-width: 100%;
      .namePivo {
        display: flex;
        gap: 10px;
        align-items: center;
        position: absolute;
        bottom: 15px;
        left: 25px;
        font-size: 25px;
        color: white;
        h3 {
          margin-right: 20px;
          font-size: 28px;
        }

        width: 100%;
        cursor: pointer;
        .iconePivo {
          font-size: 55px;
          color: white;
        }
        div {
          display: flex;
          align-items: center;
          gap: 15px;
          color: rgba(164, 164, 164, 1);
        }
      }
      .sections {
        display: flex;
        width: 100%;
        gap: 20px;
        max-width: 100%;
        justify-content: space-between;
        .left {
          padding: 40px 30px;
          border-right: 2px solid #bfbfbf;
          height: 90%;
          .pivo {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            .pivoCirculo {
              width: 350px;
              height: 350px;
              .ponteiro {
                height: 2px;
              }
            }

            .circulo {
              position: absolute;
              width: 330px;
              top: 9px;
              left: 9px;
              opacity: 0.6;
            }
          }
        }
        .center {
          display: flex;
          flex-direction: column;
          min-width: 38%;
          position: relative;
          /* height: 90%; */
          .icons {
            display: flex;
            justify-content: flex-start;
            width: 100%;

            .icon {
              font-size: 32px;
              color: #bfbfbf;
              cursor: pointer;
              transition: 0.5s;
            }
            .icon:hover {
              color: rgba(234, 234, 234, 1);
            }
          }
          h3 {
            color: #bfbfbf;
            font-size: 20px;
            margin-top: 15px;
          }
          .listCratVariable {
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            overflow: auto;
            height: 83%;
          }
          .Buttoes {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            button {
              padding: 5px 15px;
              border-radius: 5px;
              border: 1px solid transparent;
              background-color: rgba(0, 0, 0, 0.41);
              color: rgba(222, 222, 222, 0.8);
              font-size: 17px;
              cursor: pointer;
              transition: 0.8s;
            }
            button:hover {
              border: 1px solid rgba(222, 222, 222, 0.6);
            }
          }
        }
        .right {
          border-left: 2px solid #bfbfbf;
          padding-left: 10px;
          height: 90%;
          overflow-y: auto;
          overflow-x: visible;
          width: 40%;
          max-width: 100%;
          position: relative;
          h3 {
            color: rgba(204, 204, 204, 1);
            font-size: 20px;
          }
          h4 {
            color: rgba(204, 204, 204, 1);
          }
          ul {
            max-height: 90%;
            width: 100%;
            height: 90%;
            gap: 15px;
            margin-top: 20px;
            li {
              width: 45%;
              display: flex;
              justify-content: space-between;
              gap: 5px;
              .Info {
                display: flex;
                align-items: end;
                max-width: 100%;
                p {
                  font-size: 18px;
                  color: rgba(228, 228, 228, 1);
                  width: 100%;
                  max-width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                span {
                  margin-left: 5px;
                  border-bottom: 2px solid white;
                  color: rgba(228, 228, 228, 1);
                  min-width: 30px;
                  text-align: center;
                }
              }
              .icons {
                .icon {
                  font-size: 22px;
                  color: #bfbfbf;
                  cursor: pointer;
                  transition: 0.5s;
                }
                .icon:hover {
                  color: rgba(138, 74, 74, 1);
                }
              }
            }
          }
        }
      }
    }
  }
  li.expanded {
    max-height: 100%;
    padding: 10px;
  }

  @media (max-width: 1600px) {
    width: 94%;
  }
  @media (min-width: 1200px) {
    ul {
      .pivoConteiner {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
  @media (max-width: 1100px) {
    width: 92%;
    ul {
      .pivoConteiner {
        padding-left: 0%;
        padding-right: 0%;
        overflow-y: auto;

        .namePivo {
          font-size: 20px;
          bottom: 20px;
          left: 15px;
          h3 {
            margin-right: 10px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 40px;
            color: white;
          }
        }
        .sections {
          flex-direction: column;
          overflow-y: scroll;
          max-height: 90%;
          width: 100%;
          justify-content: start;
          .left {
            padding: 20px 0;
            display: flex;
            justify-content: center;
            border: none;
          }
          .center {
            width: 100%;
            min-height: 100px;
            position: relative;
            min-height: min-content;
            .listCratVariable {
              display: flex;
              flex-direction: column;
              margin-top: 25px;
              height: 83%;
              overflow: visible;
              margin-bottom: 40px;
            }
          }
          .right {
            width: 100%;
            padding: 5px 10px;
            gap: 0px;
            min-height: 80px;
            border: none;
            overflow: visible;
            min-height: min-content;
            ul {
              gap: 0;
              /* background-color: beige; */
              font-size: 14px;
              overflow: visible;
              min-height: min-content;
            }
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    width: 90%;
    ul {
      .pivoConteiner {
        .conteinerSection {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          overflow: auto;
          .center {
            order: 0;
          }
          .left {
            order: 1;
            border-right: none;
            ul {
            }
            .bombData {
              order: 1;
              h3 {
                margin-bottom: 30px;
                width: 100%;
              }
              ul {
                border-bottom: none;
              }
            }
            .rpmBomb {
              border-top: none;
              order: 0;
            }
          }
          .right {
            order: 3;
            border-left: none;
            ul {
              gap: 0;
              /* background-color: beige; */
              font-size: 16px;
            }
            .generalData {
              border-top: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    width: 85%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 15px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 15px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 98%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 25px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
            span {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const VariablesStyled = styled.span`
  position: absolute;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, transparent 50%, transparent 50%);
  transform: rotate(${(props) => props.angle + 90}deg);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 48%;
    height: 0;
    border-top: 5px dashed ${(props) => props.cor};
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 0;
    border-top: 5px dashed transparent;
  }
  transition: 2s;
  ${({ angle }) =>
    angle >= 0 && angle < 3
      ? css`
          transition: 0s;
        `
      : css`
          transition: 2s;
        `}
`;

export const InputsStyled = styled.li`
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px 8px;
  border-radius: 8px;
  border-left: 6px solid ${(props) => props.cor};
  gap: 10px;
  margin-top: 10px;
  animation: ${slideInBlurredLeft} 0.4s ease forwards;
  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    label {
      color: rgba(245, 245, 245, 1);
      position: absolute;
      left: 0px;
      font-size: 16px;
      top: -25px;
    }
    input {
      width: 100%;
      height: 25px;
      background-color: transparent;
      padding: 5px;
      border: none;
      border-bottom: 2px solid #bfbfbf;
      color: #bfbfbf;
      font-size: 18px;
      &:focus {
        outline: none;
      }
      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Remove setas no Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
  .iconss {
    display: flex;
    align-items: end;
    font-size: 40px;
    width: 80px;
    color: #bfbfbf;
    cursor: pointer;
    position: relative;
    .delete {
      position: absolute;
      bottom: -7px;
      transition: 0.5s;
    }
    .delete:hover {
      color: rgba(211, 67, 67, 1);
    }
  }
`;
