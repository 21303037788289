import styled, { css, keyframes } from "styled-components";
import MapGL from "react-map-gl";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

const trackingInExpand = keyframes`
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const blink = keyframes`
0% {
    opacity: 1; /* Começa completamente visível */
}
50% {
    opacity: 0.2; /* Fica invisível no meio da animação */
}
100% {
    opacity: 1; /* Retorna a ser completamente visível */
}
`;

export const StyledMapGl = styled(MapGL)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: red;
  .Marker {
    z-index: 200;
    background-color: red;
  }
`;

export const ButtonStart = styled.div`
  position: absolute;
  top: 50%;
  left: 45%;
  display: flex;
  justify-content: center;
  animation: float 6s ease-in-out infinite;
  z-index: 4;
  /* background: red; */
  button {
    background: rgba(0, 0, 0, 0.7);
    border: 3px solid rgba(223, 223, 223, 1);
    padding: 15px;
    font-size: 25px;
    color: rgba(223, 223, 223, 1);
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
  @media (max-width: 500px) {
    left: 25%;
  }
`;

export const AdvancedMarkerStyled = styled(AdvancedMarker)`
  scale: ${({ scale }) => scale};
  transform: translate(0px, ${({ ajuste }) => ajuste}%);
`;
export const CustomMarker = styled.div`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  scale: ${({ scale }) => scale};
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
  transform: ${({ pitch }) => pitch} ${({ bearing }) => bearing};
  .securityOn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(231, 231, 231, 1);
    font-size: 17px;
    text-shadow: rgb(179, 4, 4) 2px 0px 0px,
      rgb(179, 4, 4) 1.75517px 0.958851px 0px,
      rgb(179, 4, 4) 1.0806px 1.68294px 0px,
      rgb(179, 4, 4) 0.141474px 1.99499px 0px,
      rgb(179, 4, 4) -0.832294px 1.81859px 0px,
      rgb(179, 4, 4) -1.60229px 1.19694px 0px,
      rgb(179, 4, 4) -1.97998px 0.28224px 0px,
      rgb(179, 4, 4) -1.87291px -0.701566px 0px,
      rgb(179, 4, 4) -1.30729px -1.5136px 0px,
      rgb(179, 4, 4) -0.421592px -1.95506px 0px,
      rgb(179, 4, 4) 0.567324px -1.91785px 0px,
      rgb(179, 4, 4) 1.41734px -1.41108px 0px,
      rgb(179, 4, 4) 1.92034px -0.558831px 0px;
    .iconSecurity {
      animation: ${blink} 2s linear infinite;
      font-size: 90px;
      color: rgba(255, 44, 44, 1);
    }
    position: absolute;
    top: 10px;
    left: -45px;
    z-index: 2;
  }
  .compassRose {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 2px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    img {
      width: 152%;
      opacity: 40%;
    }
  }
`;
export const UserLoc = styled.div`
  width: 13px;
  height: 13px;
  background-color: rgba(48, 157, 175, 1);
  border-radius: 50%;
  border: 2px solid rgba(227, 227, 227, 1);
  box-shadow: 0px 1px 30px 6px rgba(5, 194, 252, 0.4);
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: -25px;
    left: -20px;
    font-size: 20px;
    color: rgba(211, 211, 211, 1);
    font-family: "Arial Black", Gadget, sans-serif;
    text-shadow: rgb(1, 1, 1) 1px 0px 0px,
      rgb(1, 1, 1) 0.540302px 0.841471px 0px,
      rgb(1, 1, 1) -0.416147px 0.909297px 0px,
      rgb(1, 1, 1) -0.989992px 0.14112px 0px,
      rgb(1, 1, 1) -0.653644px -0.756802px 0px,
      rgb(1, 1, 1) 0.283662px -0.958924px 0px,
      rgb(1, 1, 1) 0.96017px -0.279415px 0px;
    display: none;
    animation: ${trackingInExpand} 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  &:hover {
    span {
      display: inline;
    }
  }
`;

export const Teste3d = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
  position: absolute;
  z-index: 10;
  /* transform: translateY(105px) translateX(100px); */
  background: white;
  border-radius: 50%;
  border: 2px solid #b2b2b2;
  .gear {
    font-size: 60px;
    color: #b2b2b2;
    mask: black;
    transition: 1s;
  }
  .gear:hover {
    color: black;
  }
`;

export const rotateAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;
export const reverseRotation = keyframes`
from {
  transform: rotate(360deg);
}
to {
  transform: rotate(0deg);
}
`;
const introduction = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const RelogioContainer = styled.div`
  width: 100%; // Ajuste o tamanho do relógio conforme necessário
  height: 100%;
  border: 4px solid #fff;
  border-radius: 50%;
  position: relative;
  span {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 1);
    inset: 48.5% 0 0 49%;
    border-radius: 50%;
  }
`;

export const Ponteiro = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 3px;
  background-color: white;
  height: 50%;
  transform-origin: 50% 100%;
  transform: rotate(${(props) => props.angle}deg);
  /* animation: ${rotateAnimation} 300s linear infinite; */
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
  transition: 1s;
  ${({ angle }) =>
    angle >= 0 && angle < 3
      ? css`
          transition: 0s;
        `
      : css`
          transition: 2s;
        `}
`;

export const MapConfigPivos = styled.div`
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  ); */
  width: 100%;
  height: 50%;
  top: 0;
  -webkit-animation: scale-in-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  .closeConfig {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    left: 49%;
    font-size: 30px;
    color: rgba(222, 222, 222, 1);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    width: 29px;
    height: 29px;
    border-radius: 50%;
    z-index: 5;
    animation: ${introduction} 5s ease-in-out;
    opacity: 60%;
    transition: 1s;
  }
  .closeConfig:hover {
    opacity: 1;
  }

  .loadingPower {
    width: 90px;
    height: 90px;
    border: 10px solid rgba(133, 133, 133, 1);
    position: absolute;
    left: calc(50% - 45px);
    top: 97.5px;
    border-radius: 50%;
    z-index: 6;
    transform: translateX(-200px);
    border-top: 10px solid transparent;
    animation: ${rotateAnimation} 0.8s linear 0s infinite;
    cursor: not-allowed;
  }
  .pivoController {
    position: absolute;
    width: 100%;
    /* background: rgba(202, 202, 202, 0.5); */
    display: flex;
    justify-content: center;
    align-items: start;
    height: 100%;
    left: 0;
    .statusPivo {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 25px;
      top: 180px;
      gap: 10px;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: rgba(27, 27, 27, 0);
        width: 250px;
        height: 180px;
        padding: 12px;
        color: rgba(207, 207, 207, 1);
        border-radius: 10px;
        span {
          font-size: 20px;
          color: rgba(239, 239, 239, 1);
        }
      }
    }
    .contreolles {
      /* background: #b2b2b2; */
      display: flex;
      align-items: center;
      margin-top: 20px;
      gap: 55px;
      position: relative;

      img {
        width: 140px;
      }
      .center {
        display: flex;
        align-items: center;
        height: 100px;
        z-index: 5;
        .iconSecurity {
          font-size: 80px;
          color: rgba(255, 56, 56, 1);
          background-color: rgba(190, 189, 189, 0.9);
          border-radius: 50%;
          width: 90px;
          height: 90px;
        }
        img {
          width: 90px;
          z-index: 2;
        }
      }
      .pointer:hover {
        cursor: pointer;
      }
      .not-allowed {
        cursor: not-allowed;
      }
      .directionRotation {
        position: absolute;
        width: 150px;
        height: 150px;
        top: 20px;
        left: 130px;
        z-index: 5;
        .circle {
          img {
            width: 220px;
            opacity: 100%;
            z-index: 5;
          }
        }
        .controllerBackgroundD {
          position: absolute;
          left: 178px;
          bottom: 0px;
          img {
            width: 41px;
            opacity: 0%;
          }
        }
        .controllerBackgroundL {
          position: absolute;
          left: 1px;
          bottom: 0px;
          img {
            width: 41px;
            opacity: 0%;
          }
        }
        .arrowL {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: white;
          position: absolute;
          /* background: rgba(0, 0, 0, 1); */
          left: 5px;
          bottom: 37px;
          .background {
            width: 47px;
            position: absolute;
            top: -20px;
            left: -4px;
            opacity: 15%;
            cursor: pointer;
          }
          .opacity50 {
            opacity: 50%;
          }
        }
        .arrowD {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: white;
          position: absolute;
          /* background: rgba(0, 0, 0, 0.8); */
          left: 185px;
          bottom: 37px;
          .background {
            width: 47px;
            position: absolute;
            top: -20px;
            right: -4px;
            opacity: 15%;
            cursor: pointer;
          }
          .opacity50 {
            opacity: 50%;
          }
        }
      }
      .left {
        position: relative;
        margin-top: 50px;

        div {
          width: 60px;
          height: 60px;
          /* background: rgba(0, 50, 20, 0.5); */
          position: absolute;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            color: white;
            font-size: 25px;
          }
        }
        .button1 {
          top: 2px;
          left: 6%;
          width: 65px;
          transform: skewX(-11deg);
          img {
            position: absolute;
            left: -6px;
            top: -2px;
            width: 113px;
            height: 62px;
            transform: skewX(10deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-right: 8px;
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 15px;
            }
          }
        }
        .button2 {
          top: 66px;
          left: 5%;
          width: 65px;
          transform: skewX(10deg);
          img {
            position: absolute;
            top: -3px;
            left: -6px;
            width: 113px;
            transform: skewX(-10deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-right: 8px;
            gap: 3px;
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 16px;
            }
          }
        }
        .button3 {
          top: 130px;
          left: 16%;
          width: 70px;
          transform: skewX(28deg);
          img {
            position: absolute;
            top: -15px;
            left: -10px;
            width: 132px;
            height: 77px;
            transform: skewX(-27deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-right: 2px;
            gap: 3px;
            transform: skewX(-15deg);
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
      .right {
        /* background: red; */
        position: relative;
        margin-top: 50px;
        div {
          width: 60px;
          height: 60px;
          /* background: rgba(0, 50, 20, 0.5); */
          position: absolute;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 2px;
          justify-content: center;
          .icon {
            color: white;
            font-size: 25px;
          }
        }
        .button1 {
          top: 2px;
          right: 3px;
          width: 66px;
          transform: skewX(8deg);
          img {
            position: absolute;
            width: 113px;
            height: 66px;
            right: -2px;
            transform: skewX(-7deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-left: 2px;
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 15px;
            }
          }
        }
        .button2 {
          top: 66px;
          right: 5px;
          width: 65px;
          transform: skewX(-10deg);
          img {
            position: absolute;
            width: 113px;
            height: 60px;
            top: -1px;
            right: -5px;
            transform: skewX(10deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-left: 2px;
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 15px;
            }
          }
        }
        .button3 {
          top: 130px;
          right: 20px;
          width: 70px;
          transform: skewX(-25deg);
          img {
            position: absolute;
            width: 132px;
            height: 83px;
            right: -10px;
            top: -19px;
            transform: skewX(25deg);
          }
          .status {
            /* background: red; */
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Cute Font", sans-serif;
            color: white;
            margin-left: 3px;
            gap: 3px;
            transform: skewX(0deg);
            h4 {
              font-size: 15px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .linePower {
    position: absolute;
    height: 65%;
    border: 1px solid #b2b2b2;
    top: 35%;
    left: 50%;
    z-index: 1;
  }

  .pivoGraphic {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: black;
    transform: rotateX(100deg);
  }
  .bombGraphic {
    display: flex;
    width: 350px;
    height: 320px;
    margin-top: 50px;
  }
  @media (max-width: 600px) {
    .loadingPower {
      scale: 0.9;
    }
    .pivoController {
      .statusPivo {
        display: none;
      }
      .contreolles {
        scale: 0.9;
        z-index: 2;
      }
    }
  }
  @media (max-width: 480px) {
    .closeConfig {
      top: 75px;
      left: 45%;
    }
    .loadingPower {
      scale: 0.7;
      margin-top: 45px;
    }
    .pivoController {
      .contreolles {
        scale: 0.7;
        z-index: 2;
        margin-top: 65px;
      }
    }
    .linePower {
      height: 55%;
      top: 45%;
    }
  }
  @media (max-width: 380px) {
    .closeConfig {
      top: 85px;
      left: 45%;
    }
    .loadingPower {
      scale: 0.6;
    }
    .pivoController {
      .contreolles {
        scale: 0.6;
        z-index: 2;
      }
    }
  }
  @media (max-width: 325px) {
    .loadingPower {
      scale: 0.5;
    }
    .pivoController {
      .contreolles {
        scale: 0.5;
        z-index: 2;
      }
    }
  }
`;

export const MapLoding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
`;

export const MapMenus = styled.div`
  display: flex;
  width: 30%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  -webkit-animation: swing-in-left-fwd 1s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-left-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  .menuHeader {
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    position: absolute;
    top: 20px;
    left: 60px;
    font-size: 20px;
    width: 89%;
    img {
      max-width: 100%;
      width: 70px;
      height: 70px;
    }
    .closeMenu {
      position: absolute;
      right: 15px;
      font-size: 35px;
      color: rgba(158, 158, 158, 1);
      transition: 0.3s;
      cursor: pointer;
    }
    .closeMenu:hover {
      color: white;
    }
  }
  .conteinerMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    gap: 20px;
    margin-left: 65px;
    list-style: none;
    padding-top: 120px;
    overflow-y: auto;
    padding-bottom: 100px;
    .allPivos {
      color: white;
      font-size: 25px;
      cursor: pointer;
      padding: 5px 10px;
      border-left: 5px solid transparent;
      transition: 0.5s;
      background-color: rgba(223, 223, 223, 0.1);
    }
    .allPivos:hover {
      background-color: rgba(223, 223, 223, 0.2);
      border-left: 5px solid white;
    }
    .allMenuSelect {
      background-color: rgba(223, 223, 223, 0.2);
      border-left: 5px solid white;
    }
  }
  @media (max-width: 1850px) {
    width: 40%;
  }
  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 850px) {
    width: 70%;
    .menuHeader {
      .closeMenu {
        right: 25px;
      }
    }
  }
  @media (max-width: 650px) {
    .infPivo {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .menuHeader {
      left: 10px;
      .closeMenu {
        right: -15px;
      }
    }
    .conteinerMenu {
      margin-left: 5px;
    }
  }
`;

export const PivosMenu = styled.li`
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 2px;
  border: 1px solid rgba(147, 147, 147, 0.5);
  border-radius: 10px;
  position: relative;
  .alertErro {
    font-size: 25px;
    color: red;
    position: absolute;
    right: 50px;
    top: 8px;
  }
  h4 {
    display: flex;
    background-color: rgba(80, 80, 80, 0.8);
    color: white;
    font-size: 22px;
    padding: 5px;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    transition: 0.8s;
    font-family: Arial, Helvetica, sans-serif;
  }
  h4:hover {
    border-bottom: 4px solid rgba(255, 255, 255, 0.8);
  }
  .menuSelect {
    border-bottom: 4px solid rgba(255, 255, 255, 0.8);
  }
  .Star {
    position: absolute;
    right: 20px;
    top: 9px;
    font-size: 25px;
    color: rgba(172, 172, 172, 1);
    cursor: pointer;
  }
  .Fav {
    color: rgba(168, 181, 0, 1);
  }
  .pivoInf {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.5);
    padding: 15px;
    padding-bottom: 10px;
    border-radius: 10px;
    .pivostatus {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      .pivoConteiner {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        padding: 10px;
        border: 0px solid white;
        position: relative;
        transform: rotate(90deg);
        .borderPivo {
          width: 95%;
          height: 95%;
          transition: 0.5s;
          position: absolute;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          border: 4px dashed
            ${(props) =>
              props.aguaStatus
                ? "rgba(27, 116, 211, 1)"
                : "rgba(71, 70, 70, 1)"};
          animation: ${(props) =>
            props.statusPivo &&
            css`
              ${(props) =>
                props.rotationPivo
                  ? rotateAnimation
                  : reverseRotation} 50s linear infinite
            `};
        }
        .pivoAnimation {
          background-color: ${(props) =>
            props.statusPivo
              ? "rgba(23, 185, 29, 0.68)"
              : "rgba(71, 70, 70, 0.8)"};
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          .ponteiro {
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, white 50%, transparent 50%);
            transform: rotate(${(props) => props.angle}deg);
          }
          .bolinha {
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: black;
            border-radius: 50%;
            background-color: white;
          }
        }
      }
    }
    .infPivos {
      display: flex;
      max-width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      .infPivo {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: end;
        align-items: center;
        height: 80px;
        color: rgba(207, 207, 207, 0.8);
        span {
          font-size: 18px;
        }
      }
    }
  }
  /* @media (max-width: 650px) {
    .pivoInf {
      .infPivo {
        display: none;
      }
    }
  } */
`;

export const MapaGoogle = styled.div`
  width: 100%;
  height: 100%;
`;

export const SunGraphic = styled.div`
  display: flex;
  position: absolute;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  perspective: 1px; /* Ajuste o valor conforme necessário */
  transform-style: preserve-3d;
  scale: 2;
  transform: rotateY(180deg);
  z-index: 98;
  /* background-color: aqua; */

  /* Container for the circle */
  .container {
    width: 100%;
    height: 50%;
    position: relative;
    overflow: hidden;

    .circle {
      transform: ${({ pitch }) => pitch} ${({ bearing }) => bearing};
      display: flex;
      justify-content: center;
      width: 100%;
      height: ${({ size }) => size};
      border-radius: 50%;
      border: 2px solid white;
      position: relative;
      box-shadow: inset 3px 51px 56px -30px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const SunStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%; /* Ajuste conforme necessário */
  height: 100%; /* Ajuste conforme necessário */
  border-radius: 50%;

  transform: rotate(90deg) ${({ ajuste }) => ajuste} rotateY(180deg);
  z-index: 99;
  /* transform: rotateX(91deg); */
  .SunPosition {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    perspective: 2000px;
    transform-style: preserve-3d;
    width: 30px;
    height: 30px;
    z-index: 999;
    ${({ percentage }) =>
      percentage > 22 && percentage < 75
        ? css`
            background: rgb(255, 255, 255);
            background: radial-gradient(
              circle,
              rgba(255, 255, 255, 1) 14%,
              rgba(254, 166, 0, 1) 100%
            );
            box-shadow: 0px 0px 153px 25px rgba(221, 255, 0, 0.8);
          `
        : css`
            display: none;
          `}
    border-radius: 50%;
    transform: ${({ percentage }) => `rotate(${percentage * 3.6}deg) `}
      translateX(${({ vaipp }) => vaipp});

    transform-origin: center;
  }
`;
