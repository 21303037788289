import { RiAlertFill } from "react-icons/ri";
import { StyledToastAviso } from "../styles/toasts";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";

export const ToastLocation = () => {
  const [playAnimation, setPlayAnimation] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    // Define um timeout para iniciar a animação automaticamente após 30 segundos
    const timer = setTimeout(() => {
      setPlayAnimation(true);
    }, 30000); // 30 segundos

    return () => clearTimeout(timer);
  }, []);

  const handleAnimationEnd = () => {
    setHidden(true);
  };

  const handleClick = () => {
    // Inicia a animação imediatamente ao clicar
    setPlayAnimation(true);
    setTimeout(() => {
      setHidden(true);
    }, 1800);
  };

  useEffect(() => {}, [hidden]);
  return (
    <StyledToastAviso
      playAnimation={playAnimation}
      hidden={hidden}
      onAnimationEnd={handleAnimationEnd}
      onClick={handleClick}
      className={playAnimation && "animation"}
    >
      <RiAlertFill className="icon" />
      <h2>A localização Desativada</h2>
    </StyledToastAviso>
  );
};
