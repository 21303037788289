import styled from "styled-components";

export const ClimaAtualStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
  padding: 25px;
  .carregando {
    color: var(--bgBranco);
  }
  .climaAtual {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 65%;

    .localClima {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bgBranco);
      border-radius: 25px;
      font-size: 10px;
      gap: 10px;
      width: 100%;
      .iconeLocation {
        font-size: 25px;
      }
      select {
        width: 80%;
        max-width: 100%;
        border: none;
        font-size: 18px;
        background-color: var(--bgBranco);
        border-radius: 25px;
        outline: none;
      }
    }

    .diaAtual {
      h3 {
        font-size: 40px;
      }
      color: var(--bgBranco);
    }

    .temperatura {
      width: 205px;
      gap: 15px;
      .temperaturaDia {
        display: flex;
        gap: 15px;
        font-size: 55px;
        color: var(--bgBranco);
      }
      p {
        color: var(--bgBranco);
      }
    }
  }

  .statusCeu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 35%;
    .tipoCeu {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: end;
      align-items: end;
      justify-content: space-between;
      color: var(--bgBranco);
      img {
        width: 100%;
      }
      h3 {
        font-size: 25px;
      }
    }
  }
`;
