import styled from "styled-components";
import Calendar from "react-calendar";

export const StyledCalendar = styled(Calendar)`
  width: 90%;
  background: rgba(0, 0, 0, 0.7); // Fundo preto com 60% de opacidade
  height: 380px;
  border-radius: 5px;
  font-size: 25px;
  max-height: 100%;
  overflow: auto;

  // Estilizando os números do calendário
  .react-calendar__tile {
    color: white; // Números em branco
  }

  // Estilizando os números que caem no sábado e domingo
  .react-calendar__tile--weekend {
    color: red; // Números em vermelho
  }

  button {
    font-size: 25px;
    color: white;
  }
  .react-calendar__month-view__weekdays__weekday{
    color: white;
  }
  .react-calendar__tile:hover {
    background: rgba(0, 0, 0, 0.9); // Fundo preto com 90% de opacidade no hover
  }
  .react-calendar__navigation button:hover {
    background: rgba(0, 0, 0, 0.9); // Fundo preto com 90% de opacidade no hover
    color: white; // Texto em branco para melhor contraste
  }
`;
