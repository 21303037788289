import styled from "styled-components";
import imgBg from "../assets/imgs-background/background1.png";

export const HomeMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    min-height: 300px;
    gap: 30px;
    max-height: 100%;
    padding: 15px;
    h2 {
      color: white;
      font-size: 40px;
      text-align: center;
    }
    p {
      color: white;
      font-size: 25px;
      text-align: center;
    }
    .containerButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 90%;
      flex-wrap: wrap;
      .buttons {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: center;
        flex-wrap: wrap;
        button {
          color: white;
          padding: 15px;
          font-size: 20px;
          background: rgba(5, 116, 247, 0.2);
          border-radius: 10px;
          border: none;
          backdrop-filter: blur(10.9px);
          -webkit-backdrop-filter: blur(10.9px);
          cursor: pointer;
          text-align: center;
          border: 2px solid rgba(0, 0, 0, 0);
          transition: 0.3s;
        }
        button:hover {
          border: 2px solid rgba(188, 188, 188, 1);
        }
      }
    }
    background: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.9px);
    -webkit-backdrop-filter: blur(10.9px);
    min-height: min-content;
  }
  @media (max-width: 600px) {
    .container {
      width: 90%;
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 300px) {
    .container {
      width: 90%;
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 16px;
      }
    }
  }
`;

export const UnitStyled = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: max-content;
  width: 100%;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .HeaderHome {
    display: flex;
    width: 90%;
    justify-content: space-between;
    position: absolute;
    align-items: end;
    top: 15px;
    color: rgba(194, 194, 194, 1);

    h2 {
      font-size: 30px;
      color: rgba(244, 244, 244, 1);
    }
    img {
      width: 150px;
      opacity: 0;
    }
    button {
      padding: 10px;
      border-radius: 10px;
      border: none;
      background-color: rgba(0, 0, 0, 0.5);
      color: rgba(203, 203, 203, 1);
      font-size: 16px;
      cursor: pointer;
    }
    button:hover {
      color: white;
      transition: 0.5s;
    }
  }
  .listUnidades {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding-left: 65px;
    ul {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 80%;
      flex-wrap: wrap;
      list-style: none;
      gap: 20px;
      padding: 10px;
      padding-bottom: 80px;
      .listDesativat {
        opacity: 50%;
        cursor: not-allowed;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        justify-content: center;
        padding: 25px;
        border-radius: 10px;
        max-width: 200px;
        background-color: rgba(0, 0, 0, 0.5);
        color: rgba(226, 226, 226, 1);
        gap: 10px;
        height: 180px;
        min-width: 200px;
        cursor: pointer;
        border: 2px solid transparent;
        transition: 1s;
        img {
          width: 80px;
        }
        .icon {
          font-size: 80px;
        }
        h3 {
          text-align: center;
        }
      }
      li:hover {
        border: 2px solid rgba(180, 180, 180, 0.8);
      }
    }
  }

  .easterEgg {
    /* background-color: aqua; */
    position: absolute;
    bottom: 5px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    right: 15px;
    opacity: 30%;
    z-index: 9999;
    img {
      width: 100%;
    }
  }
  .tabbleStatus {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 25px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.9px);
    -webkit-backdrop-filter: blur(10.9px);
    padding: 40px 60px;
    margin-top: 6%;
    margin-bottom: 6%;
    h3 {
      font-size: 25px;
      color: #ebebeb;
    }
    table {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 25px;
      justify-content: center;
      align-items: center;
      tbody tr:first-child {
        cursor: pointer;
        transition: all 0.3s;
      }
      tbody tr:first-child:hover {
        color: white;
      }
      tbody {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        gap: 30px;
        tr {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid #d1d1d1;
          padding-bottom: 5px;
          color: #d0d0d0;
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .HeaderHome {
      width: 85%;
    }
    .tabbleStatus {
      width: 85%;
      margin-top: 20%;
      margin-bottom: 20%;
      tr {
        font-size: 14px;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 800px) {
    align-items: end;
    padding-right: 10px;
    .listUnidades {
      padding-top: 100px;
    }
    .tabbleStatus {
      width: 85%;
      margin-top: 20%;
      margin-bottom: 20%;
      tr {
        font-size: 14px;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 490px) {
    .HeaderHome {
      img {
        display: none;
      }
    }
  }
  @media (max-width: 451px) {
    .tabbleStatus {
      width: 82%;
      padding: 25px 20px;
    }
    .HeaderHome {
      img {
        display: none;
      }
    }
  }
`;
