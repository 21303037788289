import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RoutesMain } from "./routers";
import GlobalStyle from "./styles/global";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      {/* <div className="rotate-message">
        Por favor, gire o dispositivo para o modo retrato.
      </div> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <RoutesMain />
    </BrowserRouter>
  );
}

export default App;
