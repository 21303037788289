import React, { useContext } from "react";
import iconeUmidade from "../../assets/estacao-meteorologica/iconeUmidade.svg";
import { LuCalendar } from "react-icons/lu";
import { ContainerUmidadeStyled } from "../../styles/meteorologia/containerUmidade";
import { GraficoUmidadeDoAr } from "./graficoUmidade";
import { ClimaContext } from "../../contexts/contexClima";
import { PiCalendarBlankThin } from "react-icons/pi";

export const ContainerUmidade = () => {
  const { climaAtual, setModalCalender, dateDay } = useContext(ClimaContext);

  if (!climaAtual) {
    return null;
  }

  // Função para determinar o status da umidade com base no valor
  const obterStatusUmidade = (umidade) => {
    if (umidade < 30) {
      return "Baixa";
    } else if (umidade >= 30 && umidade < 60) {
      return "Moderada";
    } else if (umidade >= 60 && umidade < 80) {
      return "Alta";
    } else {
      return "Muito Alta";
    }
  };

  // Obtendo o status da umidade
  const statusUmidade = obterStatusUmidade(climaAtual.humidity);

  return (
    <ContainerUmidadeStyled>
      <div className="icone-Calendario">
        <div className="icone">
          <img src={iconeUmidade} alt="Ícone de umidade" />
          <h3>Umidade do ar</h3>
        </div>
        <div className="calendario">
          <div className="conteinerIcon" onClick={() => setModalCalender(true)}>
            <PiCalendarBlankThin />
            <span>{dateDay}</span>
          </div>
        </div>
      </div>
      <GraficoUmidadeDoAr />
      <div className="dadosDaUmidade">
        <h3>
          {climaAtual.humidity}
          <span>%</span>
        </h3>
        <p>Umidade do Ar:</p>
        <span>{statusUmidade}</span>
      </div>
    </ContainerUmidadeStyled>
  );
};
