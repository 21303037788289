import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { LuPower, LuPowerOff } from "react-icons/lu";
import { SiPivotaltracker } from "react-icons/si";
import { PivosPageStyled } from "../../styles/Pivo/pivo";
import { Calendario } from "../../components/calendario";
import { useContext, useState } from "react";
import imgPivo from "../../assets/icons/pivot.jpg";
import { useNavigate } from "react-router-dom";
import { PivoContext } from "../../contexts/contexPivos";
import { HomeContext } from "../../contexts/contexHome";
import { ModalHeader } from "../../styles/header";
import { useEffect } from "react";

export const PagePivo = () => {
  const navigate = useNavigate();

  const { pivosList } = useContext(PivoContext);
  const { isOpen, setIsOpen } = useContext(HomeContext);

  const offPivos = pivosList.filter((e) => e.status === false);
  const onPivos = pivosList.filter((e) => e.status === true);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open"); // Adiciona a classe overflow-hidden ao body quando o modal é aberto
    } else {
      document.body.classList.remove("modal-open"); // Remove a classe overflow-hidden do body quando o modal é fechado
    }

    // Limpa a classe quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <PivosPageStyled>
      <Header />
      {isOpen && <ModalHeader onClick={() => setIsOpen(!isOpen)} />}
      <div className="pivoInfos">
        <div className="pivoButton">
          <button onClick={() => navigate("/unidade1/Painelpivos")}>
            <img src={imgPivo} alt="" /> Acessar Pivôs
          </button>
        </div>
        <ul>
          <li>
            <div>
              <p>Total de Pivôs Ativos </p> <LuPower className="iconPivos on" />
            </div>
            <span>{onPivos.length}</span>
          </li>
          <li>
            <div>
              <p>ToTal de Pivôs Desativados</p>{" "}
              <LuPowerOff className="iconPivos off" />
            </div>
            <span>{offPivos.length}</span>
          </li>
          <li>
            <div>
              <p>Total de Pivôs</p> <SiPivotaltracker className="iconPivos" />
            </div>
            <span>{pivosList.length}</span>
          </li>
        </ul>
        <div className="Caledario">
          <h2>Calendário</h2>
          <Calendario />
        </div>
      </div>
      <Footer />
    </PivosPageStyled>
  );
};
