import { useContext, useState } from "react";
import { useEffect } from "react";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { HomeContext } from "../../contexts/contexHome";
import { ModalHeader } from "../../styles/header";
import { UnitStyled } from "../../styles/home";
import { Header2 } from "../../components/header2";
import logoAcls from "../../assets/logos/logo3.png";
import imgPump from "../../assets/icons/pumpWite.png";
import imgTanque from "../../assets/icons/Novotanque.png";
import { SiPivotaltracker } from "react-icons/si";
import { TbEngine } from "react-icons/tb";
import imgHridroRoll from "../../assets/icons/bobina-vetor.png";
import imgAgua from "../../assets/icons/aguaBranca.png";
import imgAguaSuja from "../../assets/icons/aguaSujaIcon.png";
import { FaCow } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { apiConnect } from "../../services/api";
import { SquareLoading } from "../../components/loaders";
import { FaCloudSun } from "react-icons/fa";
import easterEgg from "../../assets/icons/wordLogo2.gif";
import ThreeDModel from "../../components/teste3dBiblioteca";

export const Unidade1 = () => {
  const { navigate, isOpen, setIsOpen, modalAvisoOpen, setModalAvisoOpen } =
    useContext(HomeContext);

  const [loading, setloading] = useState(false);
  const [Unidades, setUnidades] = useState({});

  const unityExists = sessionStorage.getItem("connectionsUnit");

  const ListUnidade = [
    {
      titulo: "Pivô",
      status: true,
      navigate: `/${unityExists}/Painelpivos/Map`,
      img: <SiPivotaltracker className="icon" />,
      icon: true,
    },
    {
      titulo: "Estações Meteorológicas",
      status: true,
      navigate: `/${unityExists}/clima`,
      img: <FaCloudSun className="icon" />,
      icon: true,
    },
    {
      titulo: "Tanques",
      status: true,
      navigate: `/${unityExists}/tanques`,
      img: imgTanque,
      icon: false,
    },
    {
      titulo: "Motobombas",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgPump,
      icon: false,
    },
    {
      titulo: "Eletrobombas",
      status: false,
      navigate: `/${unityExists}/`,
      icon: true,
      img: <TbEngine className="icon" />,
    },
    {
      titulo: "Reservatório de Água Limpa",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAgua,
      icon: false,
    },
    {
      titulo: "Reservatório de Água Suja",
      status: false,
      navigate: `/${unityExists}/`,
      img: imgAguaSuja,
      icon: false,
    },
    {
      titulo: "HidroRoll",
      status: false,
      navigate: `/${unityExists}/`,
      icon: false,
      img: imgHridroRoll,
    },
    {
      titulo: "Biodigestor",
      status: false,
      navigate: `/${unityExists}/`,
      img: <FaCow className="icon" />,
      icon: true,
    },
  ];

  const { unidade } = useParams();

  useEffect(() => {
    const unityExists = sessionStorage.getItem("connectionsUnit");
    if (unityExists !== unidade) {
      navigate("/");
    }
    ListUnidades();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open"); // Adiciona a classe overflow-hidden ao body quando o modal é aberto
    } else {
      document.body.classList.remove("modal-open"); // Remove a classe overflow-hidden do body quando o modal é fechado
    }

    // Limpa a classe quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(
        `unidade/leitura/${idUnidade}`,
        headerApi
      );
      setUnidades(response.data);
      let locUnidade = {
        lat: response.data.latitude,
        long: response.data.longitude,
      };
      sessionStorage.setItem(
        "connectionsUnitIdloc",
        JSON.stringify(locUnidade)
      );
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  return (
    <UnitStyled>
      <Header2 />
      {loading && <SquareLoading />}
      {isOpen ? <ModalHeader onClick={() => setIsOpen(!isOpen)} /> : null}
      <div className="HeaderHome">
        <div className="Logo">
          <img src={logoAcls} alt="" />
        </div>
        <h2>Cerro</h2>
        <div>
          <button
            onClick={() => {
              sessionStorage.removeItem("connectionsUnit");
              navigate("/");
            }}
          >
            Trocar Unidade
          </button>
        </div>
      </div>
      <div className="listUnidades">
        <ul>
          {ListUnidade.map((list, index) => {
            // Verifica se Unidades está definido e se Unidades.aplicacoes[index] existe
            const aplicacaoContratada =
              Unidades &&
              Unidades.aplicacoes &&
              Unidades.aplicacoes[index] === "true";

            return (
              <li
                onClick={() => {
                  if (aplicacaoContratada) {
                    navigate(list.navigate);
                  } else {
                    setModalAvisoOpen(!modalAvisoOpen);
                  }
                }}
                className={
                  Unidades &&
                  Unidades.aplicacoes &&
                  Unidades.aplicacoes[index] === "false"
                    ? "listDesativat"
                    : ""
                }
                key={index}
              >
                <h3>{list.titulo}</h3>
                {list.icon ? list.img : <img src={list.img} alt="" />}
                <span>
                  {aplicacaoContratada ? "Contratado" : "Não Contratado"}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="easterEgg">
        <img src={easterEgg} alt="" />
      </div>

      <Footer relative={"true"} />
    </UnitStyled>
  );
};
