import { useContext, useEffect, useState } from "react";
import { Agendastyled, CustomInput } from "../../styles/Pivo/pivo";
import { PivoContext } from "../../contexts/contexPivos";
import { SiPivotaltracker } from "react-icons/si";
import { TfiArrowCircleUp } from "react-icons/tfi";
import { GiAnticlockwiseRotation, GiClockwiseRotation } from "react-icons/gi";
import { FaDroplet, FaDropletSlash } from "react-icons/fa6";
import { LuPower, LuPowerOff } from "react-icons/lu";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import {
  ButtonPivo,
  Dot,
  PivoInfAnimation,
} from "../../styles/Pivo/pivoControler";
import { TbPlantOff } from "react-icons/tb";
import { apiConnect } from "../../services/api";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAutoDelete } from "react-icons/md";
import { format } from "date-fns";
import { HomeContext } from "../../contexts/contexHome";
import {
  ModalConfirmController,
  ModalDatePersonalizadoStyled,
} from "../../styles/modals";
import { toast } from "react-toastify";
import { SquareLoading } from "../../components/loaders";

export const Agendas = ({ pivoComponente }) => {
  const {
    setExpandedPivos,
    expandedPivos,
    listPivosData,
    headerApi,
    modalPersonalizadoOn,
    setModalPersonalizadoOn,
    selectedTime,
    setSelectedTime,
    diasPersonalizados,
    setDiasPersonalizados,
    modalDeleteAgedamento,
    idAgendamento,
  } = useContext(PivoContext);

  const [time, setTime] = useState(new Date());

  const [selectedDate, setSelectedDate] = useState(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [powerPivo, setPowerPivo] = useState(true);
  const [irrigationPivo, setIrrigation] = useState(true);
  const [rotationPivo, setRotationPivo] = useState(true);

  const [selectedDateStart, setSelectedDateStart] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDateStart(date);
  };

  const toggleItemExpansion = (itemId) => {
    setExpandedPivos((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;
    var dLon = lon2 - lon1;
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);
    azimuth = azimuth / rad;
    if (azimuth < 0) {
      azimuth += 360;
    }
    return azimuth;
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const agendamento = async (idPivo) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    const dataFinal = new Date();
    let jsonAgedamento = {
      hora: time.toLocaleTimeString("pt-BR", options),
      comandos: `[${powerPivo},${irrigationPivo},false,${rotationPivo},false]`,
      data_inicio: format(selectedDateStart, "dd-MM-yyyy"),
      repeticao: selectedTime,
      limite_de_voltas: !isCheckboxChecked,
      numero_voltas: parseInt(selectedDate),
      pivo: idPivo,
    };
    if (selectedTime === "Uma vez") {
      jsonAgedamento.data_final = format(dataFinal, "dd-MM-yyyy");
    }
    if (selectedTime === "Personalizado:") {
      jsonAgedamento.repeticao = `${selectedTime} [${diasPersonalizados}]`;
    }
    if (!powerPivo) {
      jsonAgedamento.limite_de_voltas = false;
    }
    if (powerPivo && irrigationPivo === true) {
      jsonAgedamento.comandos = `[false,false,true,${rotationPivo},false]`;
    }
    try {
      const criateAgedamento = await apiConnect.post(
        `agendamentos`,
        jsonAgedamento,
        headerApi
      );

      toast.success("Agendamento criado com sucesso", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      if (error.response.data.message === "Scheduling Already exists") {
        toast.warn(
          "Este horário já está ocupado. Por favor, escolha outro horário.",
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.warn("Falha", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    if (selectedTime === "Uma vez") {
      setIsCheckboxChecked(true);
    }
  }, [selectedTime]);

  if (pivoComponente.length > 0) {
    if (listPivosData.length === 0) {
      return (
        <Agendastyled>
          <SquareLoading />
        </Agendastyled>
      );
    }
  }
  return (
    <Agendastyled>
      {modalPersonalizadoOn && <ModalDatePersonalizado />}
      {modalDeleteAgedamento && (
        <MOdalConfirmPivoAgendamento idAgendamento={idAgendamento} />
      )}
      <h2>Agendamentos</h2>
      <ul className="pivoUl">
        {pivoComponente.map((pivo) => {
          const isExpanded = expandedPivos.includes(pivo.id);

          const pivoDados = listPivosData.find((e) => pivo.id === e.pivo);

          let latitude_centro = pivoDados ? pivoDados.latitude_centro : null;
          let longitude_centro = pivoDados ? pivoDados.longitude_centro : null;
          let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
          let longitude_ponta = pivoDados ? pivoDados.longitude_ponta : null;

          let angulo = calcAzimuth(
            latitude_centro,
            longitude_centro,
            latitude_ponta,
            longitude_ponta
          );

          if (pivo.maquete === true) {
            if (pivoDados && pivoDados.rotacao_maquete !== undefined) {
              angulo = pivoDados.rotacao_maquete;
            } else {
              angulo = 0; // ou algum valor padrão
            }
          }
          return (
            <li
              key={pivo.id}
              className={`pivoConteiner ${isExpanded ? "expanded" : ""}`}
            >
              <div
                className="namePivo"
                onClick={() => toggleItemExpansion(pivo.id)}
              >
                <SiPivotaltracker className="iconePivo" />
                <h3>{pivo.name}</h3>
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_pivo ? "Ligado" : "Desligado"}
                    </span>
                    {pivoDados.status_pivo ? <LuPower /> : <LuPowerOff />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_agua ? "Irrigando" : "Seco"}
                    </span>
                    {pivoDados.status_agua ? <FaDroplet /> : <FaDropletSlash />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      |{" "}
                      {pivoDados.sentido_de_rotacao &&
                      pivoDados.sentido_de_rotacao
                        ? "Horário"
                        : "Anti-horário"}
                    </span>
                    {pivoDados.sentido_de_rotacao &&
                    pivoDados.sentido_de_rotacao ? (
                      <GiClockwiseRotation />
                    ) : (
                      <GiAnticlockwiseRotation />
                    )}
                  </div>
                )}
                {isExpanded && <TfiArrowCircleUp />}
              </div>
              <div className="sections">
                <section className="left">
                  <div className="inpults">
                    <div className="relogioPivo">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptBR}
                      >
                        <TimePicker
                          label="Selecione a hora"
                          value={time}
                          onChange={(newValue) => setTime(newValue)}
                          ampm={false}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="calender">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Selecione a data de início"
                          value={selectedDateStart}
                          onChange={handleDateChange}
                          format="dd/MM/yyyy"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="repetition">
                      <label htmlFor="time-picker">Selecione a Repetição</label>
                      <select
                        id="time-picker"
                        value={selectedTime}
                        onChange={(e) => {
                          setSelectedTime(e.target.value);
                          if (e.target.value === "Personalizado") {
                            setModalPersonalizadoOn(!modalPersonalizadoOn);
                          }
                        }}
                      >
                        <option value="Uma vez">Uma vez</option>
                        <option value="Diariamente">Diariamente</option>
                        <option value="Seg. Sex">Seg. Sex</option>
                        <option value="Personalizado">Personalizado</option>
                        <option
                          className="optionDesativ"
                          value="Personalizado:"
                        >
                          Personalizado
                        </option>
                      </select>
                    </div>
                    <div className="repetition">
                      <label htmlFor="time-picker">Numero de Voltas</label>
                      <CustomInput
                        type="number"
                        disabled={isCheckboxChecked}
                        className={isCheckboxChecked && "noClick"}
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />
                    </div>
                    <div className="checkboxFinal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                            style={{ color: "white" }}
                          />
                        }
                        label="Sem Limite de Voltas"
                        style={{ fontSize: "16px", color: "#bfbfbf" }} // Definindo o tamanho e a cor diretamente
                      />
                    </div>
                  </div>
                  {pivoDados.limite_de_voltas && (
                    <div className="lapCheck">
                      <span>
                        {pivoDados.limite_voltas - pivoDados.voltas} Voltas
                        restantes do agendamento
                      </span>
                    </div>
                  )}
                </section>
                <section className="center">
                  <div className="pivo">
                    <PivoInfAnimation
                      statusPivo={pivoDados.status_pivo}
                      aguaStatus={pivoDados.status_agua}
                      rotationPivo={pivoDados.sentido_de_rotacao}
                      angle={angulo}
                      angleVolta={pivoDados.volta_inicial}
                    >
                      <span className="borderPivo"></span>
                      <div className="pivoAnimation">
                        <span className="ponteiro"></span>
                        {pivoDados.limite_de_voltas && (
                          <span className="voltaInicial"></span>
                        )}
                        <span className="bolinha"></span>
                      </div>
                    </PivoInfAnimation>
                    <ul className="buttoes">
                      <li>
                        <p>{powerPivo ? "Ativo" : "Inativo"}</p>
                        <ButtonPivo
                          status={powerPivo}
                          cor={"rgba(62, 90, 66, 0.8)"}
                          onClick={() => setPowerPivo(!powerPivo)}
                        >
                          <Dot isLeft={powerPivo}>
                            {powerPivo ? (
                              <LuPower className="icon" />
                            ) : (
                              <LuPowerOff className="icon" />
                            )}
                          </Dot>
                        </ButtonPivo>
                      </li>
                      <li>
                        <p>{irrigationPivo ? "Irrigando" : "Seco"}</p>
                        <ButtonPivo
                          status={irrigationPivo}
                          cor={"rgba(35, 104, 174, 0.3)"}
                          onClick={() => setIrrigation(!irrigationPivo)}
                        >
                          <Dot isLeft={irrigationPivo}>
                            {irrigationPivo ? (
                              <FaDroplet className="icon" />
                            ) : (
                              <FaDropletSlash className="icon" />
                            )}
                          </Dot>
                        </ButtonPivo>
                      </li>
                    </ul>
                    <ul className="buttoes">
                      <li>
                        <p>{rotationPivo ? "Horário" : "Anti-horário"}</p>
                        <ButtonPivo
                          onClick={() => setRotationPivo(!rotationPivo)}
                        >
                          <Dot isLeft={rotationPivo}>
                            {rotationPivo ? (
                              <GiClockwiseRotation className="icon" />
                            ) : (
                              <GiAnticlockwiseRotation className="icon" />
                            )}
                          </Dot>
                        </ButtonPivo>
                      </li>
                      <li>
                        <p>Def. Agrícola OFF</p>
                        <ButtonPivo disabled={true}>
                          <Dot>
                            <TbPlantOff className="icon" />
                          </Dot>
                        </ButtonPivo>
                      </li>
                    </ul>
                  </div>
                  <div className="buttonAgendamento">
                    <button onClick={() => agendamento(pivo.id)}>
                      Agendar
                    </button>
                  </div>
                </section>
                <section className="right">
                  <h3>Agendamentos</h3>
                  <ul>
                    <PivoAgendamento pivoAgendamentoo={pivo.id} />
                  </ul>
                </section>
              </div>
            </li>
          );
        })}
      </ul>
    </Agendastyled>
  );
};

const PivoAgendamento = ({ pivoAgendamentoo }) => {
  const { headerApi, setIdAgendamento, setModalDeleteAgedamento } =
    useContext(PivoContext);

  const { attAgendamento } = useContext(HomeContext);

  const [listAgenda, setListAgenda] = useState([]);

  const getchAgendamentos = async (agendamentosId) => {
    try {
      const response = await apiConnect.get(
        `agendamentos/${agendamentosId}`,
        headerApi
      );
      setListAgenda(response.data);
    } catch (error) {
      // Tratar erro
    }
  };

  useEffect(() => {
    if (pivoAgendamentoo.length > 0) {
      getchAgendamentos(pivoAgendamentoo);
    }
  }, [pivoAgendamentoo, headerApi]);

  function numeroParaDiaDaSemana(numero) {
    const diasDaSemana = [
      "Dom ",
      "Seg ",
      "Ter ",
      "Qua ",
      "Qui ",
      "Sex ",
      "Sáb ",
    ];
    return diasDaSemana[numero];
  }

  useEffect(() => {
    getchAgendamentos(pivoAgendamentoo);
  }, [attAgendamento]);
  return (
    <ul>
      {listAgenda.length === 0 ? (
        <li>
          <div className="listAgendas">
            <span>Não Há Agendamentos Ainda</span>
          </div>
        </li>
      ) : (
        listAgenda.map((agendas) => {
          let repeticoes = agendas.repeticao.trim().split(" ");

          if (repeticoes[0] === "Personalizado:") {
            const numeros = JSON.parse(repeticoes[1]);
            const diasDaSemana = numeros.map(numeroParaDiaDaSemana);
            repeticoes = diasDaSemana;
          }

          if (repeticoes[0] === "Uma") {
            repeticoes = "Uma vez";
          }
          let arrayComandos = JSON.parse(agendas.comandos);

          return (
            <li>
              <div key={agendas.id} className="listAgendas">
                <span>{agendas.hora}</span>
                <span className="repeticao">{repeticoes}</span>
                <span>
                  {arrayComandos[0] || arrayComandos[2] ? (
                    <LuPower />
                  ) : (
                    <LuPowerOff />
                  )}
                </span>
                <span>
                  {arrayComandos[1] || arrayComandos[2] ? (
                    <FaDroplet />
                  ) : (
                    <FaDropletSlash />
                  )}
                </span>
                <span>
                  {arrayComandos[3] ? (
                    <GiClockwiseRotation />
                  ) : (
                    <GiAnticlockwiseRotation />
                  )}
                </span>
              </div>
              <div className="icons">
                <span>
                  <MdOutlineAutoDelete
                    onClick={() => {
                      setModalDeleteAgedamento(true);
                      setIdAgendamento(agendas.id);
                    }}
                  />
                </span>
              </div>
            </li>
          );
        })
      )}
    </ul>
  );
};

export const ModalDatePersonalizado = () => {
  const {
    modalPersonalizadoOn,
    setModalPersonalizadoOn,
    selectedTime,
    setSelectedTime,
    setDiasPersonalizados,
  } = useContext(PivoContext);
  const [selectedDays, setSelectedDays] = useState([]);

  const daysOfWeek = [
    { label: "Segunda-feira", value: 1 },
    { label: "Terça-feira", value: 2 },
    { label: "Quarta-feira", value: 3 },
    { label: "Quinta-feira", value: 4 },
    { label: "Sexta-feira", value: 5 },
    { label: "Sábado", value: 6 },
    { label: "Domingo", value: 0 },
  ];

  const handleCheckboxChange = (day) => {
    setSelectedDays((prevState) =>
      prevState.includes(day)
        ? prevState.filter((d) => d !== day)
        : [...prevState, day]
    );
  };

  return (
    <ModalDatePersonalizadoStyled>
      <div className="container">
        {daysOfWeek.map((day, index) => (
          <div className="inpults" key={index}>
            <input
              type="checkbox"
              id={day.value}
              value={day.value}
              checked={selectedDays.includes(day.value)}
              onChange={() => handleCheckboxChange(day.value)}
            />
            <label htmlFor={day.value}>{day.label}</label>
          </div>
        ))}
      </div>
      <div className="buttons">
        <button
          onClick={() => {
            setModalPersonalizadoOn(!modalPersonalizadoOn);
            setDiasPersonalizados(selectedDays);
            setSelectedTime("Personalizado:");
          }}
        >
          Aplicar
        </button>
        <button
          onClick={() => {
            setModalPersonalizadoOn(!modalPersonalizadoOn);
            setSelectedTime("Uma vez");
          }}
        >
          Fechar
        </button>
      </div>
    </ModalDatePersonalizadoStyled>
  );
};

export const MOdalConfirmPivoAgendamento = ({ idAgendamento }) => {
  const { headerApi, modalDeleteAgedamento, setModalDeleteAgedamento } =
    useContext(PivoContext);

  const deleteAgedamento = async (idAgendamento) => {
    try {
      const response = await apiConnect.delete(
        `agendamentos/${idAgendamento}`,
        headerApi
      );
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Deseja Deletar esse Agendamento?`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              deleteAgedamento(idAgendamento);
              setModalDeleteAgedamento(false);
            }}
          >
            Sim
          </button>
          <button onClick={() => setModalDeleteAgedamento(false)}>Não</button>
        </div>
      </div>
    </ModalConfirmController>
  );
};
