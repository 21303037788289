import React, { useEffect, useState, useContext } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiSatellite1 } from "react-icons/ci";
import { FaCloudSun, FaCloudShowersHeavy, FaSun } from "react-icons/fa";
import { GiMountaintop } from "react-icons/gi";
import { PivoContext } from "../../contexts/contexPivos";
import { HomeContext } from "../../contexts/contexHome";
import { PivoHistorico } from "./historicoPivo";
import { MenuMaps } from "./manuMap";
import { PivoControlers } from "./pivoControler";
import { Agendas } from "./agenda";
import { ConfigPivo } from "./configPivo";
import { SquareLoading } from "../../components/loaders";
import { PivoPageStyled } from "../../styles/Pivo/pivo";
import { ModalHeader } from "../../styles/header";
import {
  MOdalConfirmBombController,
  MOdalConfirmPivoController,
  ModalAlertas,
  ModalConfirmationPivoController,
} from "./modals";
import { MapController } from "./mapController";
import { GoogleMaps } from "../../components/mapGoogle";
import { Map3d } from "../../components/mapa3D";
import {
  TbChartTreemap,
  TbCurrentLocation,
  TbCurrentLocationOff,
} from "react-icons/tb";
import { Footer } from "../../components/footer";
import { HeaderPivo, HeaderPivoMobile } from "./headerPivo";
import { ToastLocation } from "../../components/avisos";
import { MapSun } from "../../components/mapSun";
import { RiSunFoggyFill } from "react-icons/ri";

export const PivoPanel = () => {
  const { isOpen, navigate, setIsOpen, user } = useContext(HomeContext);
  const {
    pivosList,
    openModalConfimPivo,
    openModalConfimBomba,
    menuMap,
    setMenuMap,
    mapConfigOn,
    setMapStyle,
    climatePivoSave,
    pivoPage,
    modalAlertaOn,
    listPivosData,
    locateMap,
    map3dOn,
    setMap3dOn,
    openModalConfimPivoComando,
    statusPvButton,
    checkingPermission,
    valueSun,
    setValueSun,
    mapSun,
    setMapSun,
    cameraState,
    setCheckingPermission,
  } = useContext(PivoContext);

  const unityExists = sessionStorage.getItem("connectionsUnit");
  const [telaPequena, setTelaPequena] = useState(window.innerWidth < 600);

  // Sol

  const handleChange = (event) => {
    setValueSun(event.target.value);
  };

  // Converte o valor do range (0 a 1439) para o formato de hora
  const convertToTime = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  // Valor mínimo é 00:00 (0 minutos) e valor máximo é 23:59 (1439 minutos)
  const minSol = 0;
  const maxSol = 1439;

  // ------------------------------------------------------------------------------------------

  useEffect(() => {
    // Adicionar/remover classe no body ao abrir/fechar modal
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  useEffect(() => {
    // Atualizar estado de tela pequena ao redimensionar a janela
    const handleResize = () => {
      setTelaPequena(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Renderização condicional para carregar enquanto pivosList estiver vazio
  if (!pivosList || (pivosList.length > 0 && listPivosData.length === 0)) {
    return (
      <PivoPageStyled>
        <SquareLoading />
      </PivoPageStyled>
    );
  }

  return (
    <PivoPageStyled>
      {/* Renderização do cabeçalho dependendo do tamanho da tela */}
      {!telaPequena && <HeaderPivo />}
      <HeaderPivoMobile />
      {checkingPermission && <ToastLocation />}

      {/* Modais e componentes condicionais baseados em estados */}
      {isOpen && <ModalHeader onClick={() => setIsOpen(!isOpen)} />}
      {openModalConfimBomba && <MOdalConfirmBombController />}
      {openModalConfimPivo && <MOdalConfirmPivoController />}
      {openModalConfimPivoComando && (
        <ModalConfirmationPivoController ststus={statusPvButton} />
      )}
      {modalAlertaOn && <ModalAlertas />}

      {/* Página principal do PivoPanel com diferentes seções */}
      {pivoPage === "Map" && (
        <section className="secMap" id="map">
          <div className="mapPivo">
            {/* Renderização do mapa 3D ou Google Maps baseado no estado de map3dOn */}
            {!mapSun ? map3dOn ? <Map3d /> : <GoogleMaps /> : null}
            {mapSun && <MapSun />}
            {/* Renderização do controlador de mapa quando mapConfigOn está ativo */}
            {mapConfigOn && !mapSun && <MapController />}
            {/* Botão de localização do usuário no mapa */}
            {checkingPermission ? (
              <div className="LocUser">
                <TbCurrentLocationOff className="icon" />
              </div>
            ) : (
              <div
                className="LocUser"
                onClick={() =>
                  locateMap(user.latitude, user.longitude, 0, 16, "user")
                }
              >
                <TbCurrentLocation className="icon" />
              </div>
            )}

            {/* Controles de estilo de mapa e visualização 3D */}
            {mapSun && (
              <div className="solNivel">
                <input
                  type="range"
                  min={minSol}
                  max={maxSol}
                  value={valueSun}
                  onChange={handleChange}
                  step={1} // Passo de 1 minuto
                  className="time-range-input"
                />
                <div className="time-display">{convertToTime(valueSun)}</div>
              </div>
            )}
            <div className="controlsSatellite">
              {mapConfigOn && (
                <button
                  onClick={() => {
                    if (mapSun) {
                      setMapSun(false);
                    } else {
                      setMapSun(true);
                    }
                  }}
                >
                  <RiSunFoggyFill />
                </button>
              )}
              <button
                onClick={() => {
                  setMap3dOn(false);
                  setMapSun(false);
                  setMapStyle("hybrid");
                }}
              >
                <CiSatellite1 />
              </button>
              <button
                onClick={() => {
                  setMap3dOn(false);
                  setMapSun(false);
                  setMapStyle("terrain");
                }}
              >
                <TbChartTreemap />
              </button>
              <button
                onClick={() => {
                  setMap3dOn(true);
                  setMapSun(false);
                  locateMap(
                    cameraState.center.lat,
                    cameraState.center.lng,
                    0,
                    14,
                    "all"
                  );
                }}
              >
                <GiMountaintop />
              </button>
            </div>
            {/* Exibição do clima atual do pivo */}
            {Object.keys(climatePivoSave).length > 0 && (
              <div className="climatePivo">
                <div className="climaConteiner">
                  {climatePivoSave.main === "Clouds" && (
                    <FaCloudSun className="icon" />
                  )}
                  {climatePivoSave.main === "Clear" && (
                    <FaSun className="icon" />
                  )}
                  {climatePivoSave.main === "Rain" && (
                    <FaCloudShowersHeavy className="icon" />
                  )}
                  <span>{climatePivoSave.temp}c</span>
                </div>
                <span
                  onClick={() => navigate(`/${unityExists}/clima`)}
                  className="verMaisClima"
                >
                  Ver Tempo
                </span>
              </div>
            )}
            {/* Renderização do menu de mapas ou botão de menu hamburguer */}
            {menuMap ? (
              <MenuMaps pivos={pivosList} />
            ) : (
              <RxHamburgerMenu
                onClick={() => setMenuMap(!menuMap)}
                className="openMenuMap"
              />
            )}
          </div>
        </section>
      )}

      {/* Outras páginas do PivoPanel */}
      {pivoPage === "Historico" && <PivoHistorico pivoComponente={pivosList} />}
      {pivoPage === "Controler" && (
        <PivoControlers pivoComponente={pivosList} />
      )}
      {pivoPage === "Agenda" && <Agendas pivoComponente={pivosList} />}
      {pivoPage === "Configuracao" && <ConfigPivo pivoComponente={pivosList} />}

      {/* Renderização do rodapé apenas se não estiver na página de mapa */}
      {pivoPage !== "Map" && <Footer />}
    </PivoPageStyled>
  );
};
