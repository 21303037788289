import { useContext, useState } from "react";
import { AdminContext } from "../../contexts/contexAdmin";
import { StatusUser, UserAdmin } from "../../styles/admin";
import { RiUserAddFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import { AiOutlineLineChart } from "react-icons/ai";
import { ModalGraphicGateway } from "./modalAdmin";

export const AdminUser = () => {
  const {
    usersSave,
    modalCreatingUser,
    setModalCreatingUser,
    setSaveUserId,
    modalUserDeletConfirmation,
    setModalUserDeletConfirmation,
    modaUpdatePassword,
    setModalUpdatePassword,
    modalEditUser,
    setModalEditUser,
    modalHistoricoGW,
    setModalHistoricoGW,
  } = useContext(AdminContext);
  const [idGatewat, setIdGateway] = useState(null);

  return (
    <UserAdmin>
      {modalHistoricoGW && <ModalGraphicGateway gatewayId={idGatewat} />}
      <div className="Header">
        <h2>Usuários</h2>
        <div className="addIcon">
          <RiUserAddFill
            onClick={() => {
              setModalCreatingUser(!modalCreatingUser);
            }}
          />
          <span>Criar Novo Usuários</span>
        </div>
      </div>
      <ul>
        {usersSave.map((user) => {
          return (
            <li key={user.id}>
              {user.gateway !== null && (
                <AiOutlineLineChart
                  className="iconGt"
                  onClick={() => {
                    setIdGateway(user.gateway);
                    setModalHistoricoGW(true);
                  }}
                />
              )}
              <ImBin
                className="iconBin"
                onClick={() => {
                  setSaveUserId(user.id);
                  setModalUserDeletConfirmation(!modalUserDeletConfirmation);
                }}
              />
              <FaUserEdit
                className="iconEdit"
                onClick={() => {
                  setSaveUserId(user.id);
                  setModalEditUser(!modalEditUser);
                }}
              />
              <StatusUser
                color={
                  user.online
                    ? "rgba(41, 162, 11, 1)"
                    : "rgba(131, 131, 131, 0.6)"
                }
              >
                <span className="online"></span>
                <span>{user.online ? "Online" : "Offline"}</span>
              </StatusUser>
              <span>E-mail : {user.email}</span>
              <span>Usuário : {user.username}</span>
              <span>Controlador : {user.permission ? "Sim" : "Não"}</span>
              <span>Admin : {user.is_superuser ? "Sim" : "Não"}</span>
              <button
                onClick={() => {
                  setSaveUserId(user.id);
                  setModalUpdatePassword(!modaUpdatePassword);
                }}
              >
                Alterar Senha
              </button>
              <button
                onClick={() => {
                  setSaveUserId(user.id);
                  setModalEditUser(!modalEditUser);
                }}
              >
                Editar Usuário
              </button>
            </li>
          );
        })}
      </ul>
    </UserAdmin>
  );
};
