import axios from "axios";

export const apiConnect = axios.create({
  baseURL: "https://aclsconnect.com/67ZlfPVt/",
  timeout: 8000,
});

export const urlWebSockt = "https://aclsconnect.com";

// Api Local
// export const apiConnect = axios.create({
//   baseURL: "http://192.168.1.119:8000/67ZlfPVt/",
//   timeout: 8000,
// });

// local;
// export const urlWebSockt = "http://192.168.1.119:8000";

//Api de tempo openweathermap
export const tempoApi = axios.create({
  baseURL: "https://api.openweathermap.org/data/2.5/",
  timeout: 8000,
});
