import React, {useState } from "react";
import { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { StyledCalendar } from "../styles/calendario";

export const Calendario = () => {
  const [value, onChange] = useState(new Date());

  
useEffect(()=>{
},[value])
  return (
      <StyledCalendar onChange={onChange} value={value}  />
  );
};
