import { LoginMain } from "../../styles/login";
import { FormLogin } from "./loginForm";

export const Login = () => {
  return (
    <LoginMain>
      <FormLogin />
    </LoginMain>
  );
};
