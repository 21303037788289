import styled from "styled-components";
import { css } from "styled-components";

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-top: 2px solid #9c9c9c;
  gap: 20px;
  ${({ relative }) =>
    relative
      ? css`
          position: absolute;
          bottom: 0;
          z-index: 2;
        `
      : css`
          position: relative;
        `}
  span {
    color: #b0b0b0;
  }
  ul {
    display: flex;
    gap: 8px;
    list-style: none;
    li {
      color: #9c9c9c;
      cursor: pointer;
      a {
        text-decoration: none;
        text-decoration: none !important;
        color: #9c9c9c;
      }
      a:hover {
        color: white;
      }
    }
  }
`;
