import { NotFoundStyled } from "../styles/pageNotFound";
import imgNotFound from "../assets/imgs-background/error404.gif";

export const NotFound = () => {
  return (
    <NotFoundStyled>
      <img src={imgNotFound} alt="" />
    </NotFoundStyled>
  );
};
