import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Outlet, useNavigate } from "react-router-dom";
import { HomeContext } from "./contexHome";
import { apiConnect } from "../services/api";

export const TanqueContextC = createContext({});

export const TanqueProvider = () => {
  const { socket } = useContext(HomeContext);

  const token = sessionStorage.getItem("connectionsToken");
  const headerApi = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [modalStatus, setModalStatus] = useState(false);
  const [modalAlertas, setModalAlertas] = useState(false);
  const [modalConfimetionBomba, setModalConfimationBomba] = useState(false);
  const [tanquesList, setTanquesList] = useState(null);
  const [bombasTanqueList, setBombasTanqueList] = useState([]);
  const [bombaControlerList, setBombaControlerList] = useState([]);
  const [alertaList, setAlertaList] = useState([]);
  const [idControlerBomba, setIdControlerBomba] = useState(null);

  const ListTanques = async () => {
    try {
      const response = await apiConnect.get("tanques/LeituraTodos", headerApi);
      const idUnidade = sessionStorage.getItem("connectionsUnitId");
      const filterTanques = response.data.filter(
        (e) => e.unidade.id === Number(idUnidade)
      );
      setTanquesList(filterTanques);
    } catch (error) {}
  };

  const ListBombasTanque = async () => {
    try {
      const response = await apiConnect.get(
        "tanques/bomba/LeituraTodos",
        headerApi
      );
      setBombasTanqueList(response.data);
    } catch (error) {}
  };

  const ListControlerBombasTanques = async () => {
    try {
      const response = await apiConnect.get(
        `tanques/bomba/controle/LeituraTodos`,
        headerApi
      );
      setBombaControlerList(response.data);
    } catch (error) {}
  };

  const listAlertas = async () => {
    const token = sessionStorage.getItem("connectionsToken");
    const unidadeStorege = sessionStorage.getItem("connectionsUnitId");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiConnect.get(
      "/tanques/alertas/LeituraTodos",
      headerApi
    );
    const filterUnidade = response.data.filter(
      (e) => e.tanque.unidade.id === Number(unidadeStorege)
    );
    setAlertaList(filterUnidade);
  };

  useEffect(() => {
    ListTanques();
    ListBombasTanque();
    listAlertas();
    ListControlerBombasTanques();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("edit_bomba-tanque", (dadosBomba) => {
        ListBombasTanque();
      });

      socket.on("edit_bomba_controler-tanque", (controlerBomba) => {
        ListControlerBombasTanques();
      });

      socket.on("edit_nivel-tanque", (tanqueList) => {
        ListTanques();
      });
    }
  }, [socket]);

  return (
    <TanqueContextC.Provider
      value={{
        setModalAlertas,
        modalAlertas,
        tanquesList,
        ListTanques,
        setModalStatus,
        modalStatus,
        bombasTanqueList,
        alertaList,
        setAlertaList,
        bombaControlerList,
        modalConfimetionBomba,
        setModalConfimationBomba,
        idControlerBomba,
        setIdControlerBomba,
      }}
    >
      <Outlet />
    </TanqueContextC.Provider>
  );
};
