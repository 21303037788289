import { useNavigate } from "react-router-dom";
import { Bar, HamburgerMenuContainer, HeaderStyled } from "../styles/header";
import { IoHome, IoPersonCircle, IoExit } from "react-icons/io5";
import logoHader from "../assets/logos/acls_branco.png";
import { useContext, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import imgPivo from "../assets/icons/pivot.jpg";
import imgTanque from "../assets/icons/tanques.png";
import imgResevatorio from "../assets/icons/tanques.png";
import imgResevatorioRed from "../assets/icons/aguasuja.png";
import imgMotor from "../assets/icons/motobomba.png";
import imgEletroMortor from "../assets/icons/pump.png";
import { HomeContext } from "../contexts/contexHome";

export const Header = ({ relative }) => {
  const { user, isOpen, setIsOpen } = useContext(HomeContext);
  const [relativeBooleano, setRelativeBoleano] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (relative === "false") {
      setRelativeBoleano(false);
    } else {
      setRelativeBoleano(true);
    }
  }, []);

  return (
    <HeaderStyled relative={relativeBooleano}>
      <section className="headerLeft">
        {/* <CSSTransition
          in={isOpen}
          timeout={5000}
          classNames="slide-in-left"
          unmountOnExit
        >
          <ul className="hambuguerMenu">
            <li className="logoMenu">
              <div className="imgLogo">
                <img src={logoHader} alt="" />
              </div>
              <p>ACLS TECNOLOGIA</p>
            </li>
            <li className="mainMenu">
              <span>Menu Principal</span>
              <div
                onClick={() => {
                  navigate("/");
                  setIsOpen(!isOpen);
                }}
              >
                <IoHome className="iconHome" />
                <span>Inicio</span>
              </div>
            </li>
            <li className="listApllications">
              <ul>
                <li
                  className="isActive"
                  onClick={() => {
                    navigate("/unidade1/Painelpivos");
                    setIsOpen(!isOpen);
                  }}
                >
                  <img src={imgPivo} alt="" />
                  <p>Pivôs</p>
                </li>
                <li>
                  <img src={imgTanque} alt="" />
                  <p>Tanques de Vinhaça</p>
                </li>
                <li>
                  <img src={imgMotor} alt="" />
                  <p>MotoBombas</p>
                </li>
                <li>
                  <img src={imgEletroMortor} alt="" />
                  <p>EletroBombas</p>
                </li>
                <li>
                  <img src={imgResevatorio} alt="" />
                  <p>Reservaório Águua Limpa</p>
                </li>
                <li>
                  <img src={imgResevatorioRed} alt="" />
                  <p>Reservaório Águua Suja</p>
                </li>
                <li>
                  <img src={imgEletroMortor} alt="" />
                  <p>HidroRoll</p>
                </li>
              </ul>
            </li>
          </ul>
        </CSSTransition>
        <div className="hambugerConteiner">
          <HamburgerMenuContainer
            className={isOpen ? "open true" : ""}
            onClick={toggleMenu}
          >
            <Bar />
            <Bar />
            <Bar />
          </HamburgerMenuContainer>
        </div> */}
      </section>
      <section className="headerRight">
        <span>{user.username}</span>
        <IoPersonCircle className="iconHeader" />
        <IoExit
          className="iconExit"
          onClick={() => {
            sessionStorage.removeItem("connectionsToken");
            sessionStorage.removeItem("connectionsUnit");
            navigate("/Login");
          }}
        />
      </section>
    </HeaderStyled>
  );
};
