import { createContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { apiConnect, urlWebSockt } from "../services/api";
import io from "socket.io-client";

export const HomeContext = createContext({});

export const HomeProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const [userOn, setUserOn] = useState({});

  const [attUser, setAttUser] = useState([]);
  const [attHistoricoPivo, setAttHistoricoPivo] = useState("");
  const [attAgendamento, setAttAgendamentos] = useState("");
  const [modalAvisoOpen, setModalAvisoOpen] = useState(false);

  const [locationUser, setLocationUser] = useState({ lat: null, lng: null });
  const [stopLoc, setStopLoc] = useState(false);

  const token = sessionStorage.getItem("connectionsToken");

  const headerApi = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!token) {
      sessionStorage.clear();
      navigate("/login");
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationUser({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log("Sem permissao LOC");
        }
      );
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  useEffect(() => {
    const newSocket = io(urlWebSockt, {
      path: "/67ZlfPVt/socket.io",
      auth: {
        token: token,
      },
      transports: ["websocket", "polling"],
    });
    let usuarioSave;

    setSocket(newSocket);
    newSocket.on("connect", () => {
      console.log("WebSocket connected");
    });

    // Sokets Dos Usuarios
    newSocket.on("user_data", (userData) => {
      setUser(userData);
      setTimeout(() => (usuarioSave = userData), 1000);
    });

    newSocket.on("user_created", (Newuser) => {
      setAttUser(["Criado", Newuser]);
    });

    newSocket.on("user_Update", (Updatuser) => {
      setAttUser(["Edit", Updatuser]);
      if (Updatuser.id === usuarioSave.id) {
        usuarioSave = Updatuser;
        setUser(Updatuser);
      }
    });

    newSocket.on("user_deleted", (userId) => {
      setAttUser(["Deletado", userId]);
    });
    newSocket.on("changed_user", (userId) => {
      setUserOn(userId);
    });

    // Historico Pivo

    newSocket.on("pivo_historico_creat", (alertasPivossoket) => {
      setAttHistoricoPivo(alertasPivossoket);
    });

    //Agendamento

    newSocket.on("pivo_agenda_notificao", (agendaPivossoket) => {
      setAttAgendamentos(agendaPivossoket);
    });

    newSocket.on("User_desconectado_sessao", (userId) => {
      if (usuarioSave && usuarioSave.id === userId) {
        sessionStorage.removeItem("connectionsToken");
        sessionStorage.removeItem("connectionsUnit");
        navigate("/Login");
        toast.warn("Novo Dispositivo Conectado!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    });

    newSocket.on("connect_error", (err) => {
      // Exibe uma mensagem mais amigável se a propriedade 'data' estiver disponível
      if (err.data) {
        sessionStorage.removeItem("connectionsToken");
        sessionStorage.removeItem("connectionsUnit");
        navigate("/Login");
        toast.warn("Sessão expirada", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      } else {
        console.error("WebSkoket Error"); // Caso contrário, usa a mensagem de erro padrão
        sessionStorage.removeItem("connectionsToken");
        sessionStorage.removeItem("connectionsUnit");
        navigate("/Login");
        toast.warn("Algo de errado 😅", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    });

    return () => {
      newSocket.close();
    };
  }, [token]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (user && user.id) {
        setTimeout(async () => {
          let latLog = {
            latitude: locationUser.lat,
            longitude: locationUser.lng,
            is_superuser: true,
          };
          try {
            if (latLog.latitude !== null) {
              if (!stopLoc) {
                await apiConnect.patch(`users/${user.id}/`, latLog, headerApi);
                setStopLoc(true);
              }
            }
          } catch (error) {}
        }, 5000);
      }
    }
  }, [locationUser, user]);

  return (
    <HomeContext.Provider
      value={{
        user,
        navigate,
        isOpen,
        setIsOpen,
        location,
        userOn,
        attUser,
        attHistoricoPivo,
        attAgendamento,
        setAttAgendamentos,
        modalAvisoOpen,
        setModalAvisoOpen,
        socket,
      }}
    >
      <Outlet />
    </HomeContext.Provider>
  );
};
