import styled from "styled-components";

export const ContainerVentoStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  max-height: 100%;
  justify-content: space-around;

  .iconeCalenario {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icone {
      display: flex;
      gap: 10px;
      h3 {
        font-size: 20px;
      }
      img {
        width: 50px;
      }
    }
    .calendario {
      display: flex;
      align-items: center;
      font-size: 50px;
      height: fit-content;
      max-height: 100%;
      position: relative;
      cursor: pointer;
      .conteinerIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 18px;
          position: absolute;
          top: 19px;
          left: 14px;
        }
      }
    }
  }
  .dadosDoVento {
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 120px;
    .velocidade {
      width: 50%;
      h3 {
        font-size: 30px;
        margin-top: 10px;
        span {
          font-size: 20px;
        }
      }
      p {
        font-size: 10px;
      }
      span {
        font-size: 15px;
      }
    }

    .direcaoVento {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      max-width: 100%;
      justify-content: end;
      width: fit-content;
      width: 50%;
      p {
        font-size: 14px;
      }
    }
  }
`;

export const GraficoVentoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  height: 100%;
  background-color: var(--bgGrafico);
  max-height: 65%;
  .graficoVento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5px;
  }
`;
