import { useParams } from "react-router-dom";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { HeaderClima } from "./headerClima";
import { EstacaoClimaStyled } from "../../styles/meteorologia/estacaoMeteorologica";
import { ClimaAtual } from "./tempoAtual";
import { PrevisaoDoTempo } from "./previsaoTempo";
import { ContainerVento } from "./containerVento";
import { ContainerUmidade } from "./containerUmidade";
import iconePressao from "../../assets/estacao-meteorologica/iconePressao.svg";
import temperatura from "../../assets/estacao-meteorologica/iconeTemperatura.svg";
import { ComboGraficos } from "./containerComboGraficos";
import { LuCalendar } from "react-icons/lu";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useContext, useState } from "react";
import { GraficoPressaoAtmosferica } from "./graficoPressao";
import { GraficoTemperatura } from "./graficoTemperatura";
import { Header2 } from "../../components/header2";
import { ClimaContext } from "../../contexts/contexClima";
import Calendar from "react-calendar";
import { RiCloseCircleFill } from "react-icons/ri";
import { PiCalendarBlankThin } from "react-icons/pi";
import { PivoContext } from "../../contexts/contexPivos";
import { format } from "date-fns";
import { apiConnect } from "../../services/api";

export const EstacaoMeteorologica = () => {
  const {
    modalCalender,
    setModalCalender,
    calenderDate,
    setCalenderDate,
    dateDay,
    setDateDay,
    climaIdSelect,
    setListHistorico,
  } = useContext(ClimaContext);

  const [tipoGrafico, setTipoGrafico] = useState("temperatura");
  const [selectGrafico, setSelectGrafico] = useState(false);

  const filterHistorico = async () => {
    const formatData = format(calenderDate, "dd-MM-yyyy");
    setDateDay(calenderDate.getDate());
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await apiConnect(
        `meteorologia/historico/${climaIdSelect}/${formatData}`,
        headerApi
      );
      setListHistorico(response.data);
    } catch (error) {
      if (
        error.response.data.message ===
        "Não foram encontrados históricos para o localidade e data especificados."
      ) {
        setListHistorico([]);
      }
    } finally {
      setModalCalender(false);
    }
  };

  return (
    <EstacaoClimaStyled>
      {/* <HeaderClima /> */}
      <Header2 />
      {modalCalender && (
        <div className="calendarDiv">
          <span className="close" onClick={() => setModalCalender(false)}>
            <RiCloseCircleFill />
          </span>
          <h3>Seleciona uma data de histórico.</h3>
          <Calendar
            className="calendar"
            onChange={setCalenderDate}
            value={calenderDate}
            maxDate={new Date()}
          />
          <div className="buutons">
            <button onClick={() => filterHistorico()}>Pesquisar</button>
            <button onClick={() => setModalCalender(false)}>Cancelar</button>
          </div>
        </div>
      )}
      <section className="secaoGeral">
        <section className="blocosA">
          <div className="blocoA">
            <ClimaAtual />
          </div>
          <div className="blocoA Previsao">
            <PrevisaoDoTempo />
          </div>
        </section>
        <section className="blocosB">
          <section className="blocoB">
            <div className="blocoB-AB">
              <section className="blocoB-A">
                <ContainerVento />
              </section>
              <section className="blocoB-B">
                <ContainerUmidade />
              </section>
            </div>
            <section className="blocoB-C">
              <div className="headerGraficos">
                <button
                  onClick={() => setSelectGrafico(!selectGrafico)}
                  className="botao"
                >
                  {selectGrafico ? <FaChevronDown /> : <FaChevronUp />}
                </button>
                <div className="SelectTitulo">
                  {tipoGrafico === "pressao" && (
                    <div
                      className="titulo"
                      onClick={() => setSelectGrafico(!selectGrafico)}
                    >
                      <img className="iconePressao" src={iconePressao} alt="" />
                      <h3>Pressão Atmosférica</h3>
                    </div>
                  )}
                  {tipoGrafico === "temperatura" && (
                    <div
                      className="titulo"
                      onClick={() => setSelectGrafico(!selectGrafico)}
                    >
                      <img
                        className="iconeTemperatura"
                        src={temperatura}
                        alt=""
                      />
                      <h3>Temperatura</h3>
                    </div>
                  )}

                  {selectGrafico && (
                    <ul className="Seletor">
                      <li
                        onClick={() => {
                          setTipoGrafico("pressao");
                          setSelectGrafico(!selectGrafico);
                        }}
                      >
                        <h3>Pressão Atmosférica</h3>
                      </li>
                      <li
                        onClick={() => {
                          setTipoGrafico("temperatura");
                          setSelectGrafico(!selectGrafico);
                        }}
                      >
                        <h3>Temperatura</h3>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="calendario">
                  <div
                    className="conteinerIcon"
                    onClick={() => setModalCalender(true)}
                  >
                    <PiCalendarBlankThin />
                    <span>{dateDay}</span>
                  </div>
                </div>
              </div>
              {tipoGrafico === "pressao" && <GraficoPressaoAtmosferica />}
              {tipoGrafico === "temperatura" && <GraficoTemperatura />}
            </section>
          </section>
        </section>
      </section>
      <Footer />
    </EstacaoClimaStyled>
  );
};
