import { LoginFormaStyled } from "../../styles/login";
import imgLogo from "../../assets/logos/logoAclsB.png";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineLockPerson } from "react-icons/md";
import { useContext, useState } from "react";
import { LoginContext } from "../../contexts/contexLogin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const FormLogin = () => {
  const { loading, handleSubmitLogin } = useContext(LoginContext);
  const [viewPassword, setViewPassword] = useState("password");

  const formSchema = yup.object().shape({
    username: yup.string().required("Nome do Usuário obrigatório"),
    password: yup.string().required("Senha obrigatória"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <LoginFormaStyled onSubmit={handleSubmit(handleSubmitLogin)}>
      <h1>Login</h1>
      <img src={imgLogo} alt="" className="logoForm" />
      <div className="Inpults">
        <input
          type="text"
          required={true}
          placeholder={"Nome de usuário"}
          {...register("username")}
        />
        <div className="iconLogin">
          <IoPersonOutline />
        </div>
      </div>
      <div className="Inpults">
        <input
          type={viewPassword}
          id="password"
          required={true}
          placeholder={"Senha"}
          {...register("password")}
        />
        <div className="iconLogin">
          {viewPassword === "password" ? (
            <FaEye
              className="viewPassword"
              onClick={() => setViewPassword("text")}
            />
          ) : (
            <FaEyeSlash
              className="viewPassword"
              onClick={() => setViewPassword("password")}
            />
          )}
          <MdOutlineLockPerson />
        </div>
      </div>
      <div className="buttons">
        <button
          className="buttonLogin"
          type="submit"
          disabled={loading ? true : false}
        >
          {loading ? "Carregando" : "Acessar"}
        </button>
      </div>
      <span className="formFooter"></span>
      <span className="Copyright">Copyright © ACLS Tecnologia 2024</span>
    </LoginFormaStyled>
  );
};
