import React, { useContext } from "react";
import { ContainerVentoStyled } from "../../styles/meteorologia/containerVento";
import iconeVento from "../../assets/estacao-meteorologica/iconeVento.svg";
import { LuCalendar } from "react-icons/lu";
import { GraficoDoVento } from "./graficoVento";
import { ClimaContext } from "../../contexts/contexClima";
import { PiCalendarBlankThin } from "react-icons/pi";

export const ContainerVento = () => {
  const { climaAtual, dateDay, setModalCalender } = useContext(ClimaContext);

  if (!climaAtual) {
    return null;
  }

  const obterDirecaoDoVento = (deg) => {
    // Define os limites das direções cardeais em graus
    const direcoes = [
      { nome: "Norte", min: 348.75, max: 11.25 },
      { nome: "Nordeste", min: 11.25, max: 33.75 },
      { nome: "Leste-Nordeste", min: 33.75, max: 56.25 },
      { nome: "Leste", min: 56.25, max: 78.75 },
      { nome: "Leste-Sudeste", min: 78.75, max: 101.25 },
      { nome: "Sudeste", min: 101.25, max: 123.75 },
      { nome: "Sul-Sudeste", min: 123.75, max: 146.25 },
      { nome: "Sul", min: 146.25, max: 168.75 },
      { nome: "Sul-Oeste", min: 168.75, max: 191.25 },
      { nome: "Oeste", min: 191.25, max: 213.75 },
      { nome: "Oeste-Noroeste", min: 213.75, max: 236.25 },
      { nome: "Noroeste", min: 236.25, max: 258.75 },
      { nome: "Noroeste-Norte", min: 258.75, max: 281.25 },
      { nome: "Norte-Noroeste", min: 281.25, max: 303.75 },
      { nome: "Norte-Nordeste", min: 303.75, max: 326.25 },
    ];

    // Ajusta o valor de `deg` para o intervalo 0-360
    deg = ((deg % 360) + 360) % 360;

    // Encontra a direção correspondente
    for (const direcao of direcoes) {
      if (deg >= direcao.min && deg < direcao.max) {
        return direcao.nome;
      }
    }

    // Caso não encontre, retorna 'Desconhecido'
    return "Desconhecido";
  };

  function getWindType(speedKmh) {
    if (speedKmh < 1) return "Calmo";
    if (speedKmh <= 5) return "Brisa Muito Leve";
    if (speedKmh <= 11) return "Brisa Leve";
    if (speedKmh <= 19) return "Brisa Fraca";
    if (speedKmh <= 28) return "Brisa Moderada";
    if (speedKmh <= 38) return "Brisa Fresca";
    if (speedKmh <= 49) return "Vento Forte";
    if (speedKmh <= 61) return "Vento Muito Forte";
    if (speedKmh <= 74) return "Vento Forte a Moderado";
    if (speedKmh <= 88) return "Vento Forte a Violento";
    if (speedKmh <= 102) return "Tempestade";
    if (speedKmh <= 117) return "Tempestade Violenta";
    return "Furacão";
  }

  return (
    <ContainerVentoStyled>
      <div className="iconeCalenario">
        <div className="icone">
          <img src={iconeVento} alt="" />
          <h3>Velocidade do Vento</h3>
        </div>
        <div className="calendario">
          <div className="conteinerIcon" onClick={() => setModalCalender(true)}>
            <PiCalendarBlankThin />
            <span>{dateDay}</span>
          </div>
        </div>
      </div>
      <GraficoDoVento />
      <div className="dadosDoVento">
        <div className="velocidade">
          <h3>
            {(climaAtual.wind_speed * 3.6).toFixed(1)} <span>km/h</span>
          </h3>
          <p>Intensidade do Vento:</p>
          <span>
            {getWindType((climaAtual.wind_gust * 3.6).toFixed(1))} (
            {(climaAtual.wind_gust * 3.6).toFixed(1)} km/h)
          </span>
        </div>
        <div className="direcaoVento">
          <p>Direção do Vento:</p>
          <span>{obterDirecaoDoVento(climaAtual.wind_deg)}</span>
        </div>
      </div>
    </ContainerVentoStyled>
  );
};
