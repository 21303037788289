import styled, { css, keyframes } from "styled-components";

export const bounceOutTop = keyframes`
  0% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  5% {
    transform: translateY(-30px);
    animation-timing-function: ease-in;
  }
  15% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  25% {
    transform: translateY(-38px);
    animation-timing-function: ease-in;
  }
  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  52% {
    transform: translateY(-75px);
    animation-timing-function: ease-in;
  }
  70% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translateY(-800px);
    opacity: 0;
  }
`;

export const slideOutTop = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
`;

export const StyledToastAviso = styled.div`
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 400px;
  left: 35%;
  background-color: rgba(250, 250, 250, 0.81);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  height: 50px;
  gap: 5px;
  z-index: 999;
  cursor: pointer;
  ${(props) =>
    props.playAnimation &&
    css`
      animation: ${slideOutTop} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      animation-delay: 30s; /* Inicia a animação após 30 segundos */
    `}
  display: ${(props) => (props.hidden ? "none" : "flex")};
  .icon {
    font-size: 28px;
    color: rgba(201, 201, 6, 1);
  }
  .closeModal {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  @media (max-width: 800px) {
    left: 10%;
    width: 200px;
    font-size: 8px;
  }
`;
