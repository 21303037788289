import styled from "styled-components";

export const PrevisaoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  color: var(--bgBranco);
  padding: 18px;
  max-width: 100%;
  .tituloPrevisao {
    font-size: 15px;
  }
  .diasSemana {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    list-style-type: none;
    width: 100%;
    height: 100%;
    max-height: 100%;
    gap: 3%;
    max-width: 100%;
    /* background-color: aqua; */
    .dia {
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 85%;
      max-width: 100%;
      padding: 15px 0;
      border-radius: 2em;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      background-color: var(--background);
      border: 1px solid rgba(248, 248, 248, 0.2);
      transition: 0.3s;
      position: relative;
      padding-top: 28px;
      margin-top: 10px;
      img {
        position: absolute;
        width: 40%;
        top: -15px;
        right: -10px;
      }
      .diaSemana {
        position: absolute;
        max-width: 100%;
        top: -23px;
        font-weight: 600;
        text-decoration: 2px underline solid rgba(255, 255, 255, 1);
      }
      .tempPre {
        font-size: 1vw;
        font-weight: 600;
      }
      .Status {
        font-weight: 600;
        font-size: 0.9vw;
        text-transform: capitalize;
        max-width: 100%;
        text-align: center;
      }
    }
    .dia:hover {
      border: 1px solid var(--bgBranco);
    }
  }

  .previsaoDoDia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    width: 100%;
    height: 55%;
    border-radius: 15px;
    background-color: var(--background);
    gap: 15px;
    padding: 10px;
    border: 1px solid rgba(248, 248, 248, 0.1);
    /* background-color: aqua; */
    list-style: none;
    .nowPrev {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 15%;
      /* justify-content: center; */
      /* background-color: aqua; */
      height: 100%;
      padding-bottom: 5px;
      .icon {
        font-size: 1.8vw;

        img {
          width: 85%;
        }
      }
      span {
        font-weight: 600;
      }
      border-bottom: 3px solid white;
    }

    .diaPrevs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding-bottom: 5px;
      border-bottom: 3px solid transparent;
      span {
        font-weight: 600;
      }
      .icon {
        font-size: 1.8vw;

        img {
          width: 85%;
        }
      }
    }
    .hoje {
      span {
        font-size: 30px;
      }
      p {
        font-size: 10px;
      }
    }
    .botaoPrevisao {
      button {
        width: 200px;
        height: 40px;
        background-color: var(--bgBotao);
        color: var(--bgBranco);
        border-radius: 15px;
        cursor: pointer;
        border: none;
      }
      button:hover {
        border: 1px solid var(--bgBranco);
      }
    }

    .statusDia {
      display: flex;
      flex-direction: column;

      align-items: end;
      gap: 15px;
      img {
        width: 50px;
      }
    }
  }
`;
