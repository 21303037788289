import React from "react";
import styled, { css } from "styled-components";
import { rotateAnimation } from "../styles/Pivo/map";

const Circle = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  /* animation: ${rotateAnimation} 300s linear infinite; */
  transform: rotate(${(props) => props.angle}deg);
  transition: 1s;
`;

const Slice = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.AguaPivo
      ? css`  conic-gradient(
    from 360deg,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 0),
    rgba(0, 128, 0, 0.5),
    rgba(4, 183, 4, 0.7),
    rgba(4, 183, 4, 0.7),
    rgba(0, 166, 255, 0.5),
    rgba(0, 166, 255, 0.5)
  );`
      : css`
   conic-gradient(
    from 360deg,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 0),
    rgba(32, 32, 32, 0),
    rgba(82, 82, 82, 0.567),
    rgba(65, 65, 65, 0.754),
    rgba(51, 50, 50, 0.625),
    rgba(9, 9, 9, 0.692)
  );
  `};
`;

const PieChart = ({ statusAgua, angulo }) => {
  return (
    <Circle angle={angulo}>
      <Slice AguaPivo={statusAgua} />
    </Circle>
  );
};

export default PieChart;
