import React, { createContext, useState, useEffect, useContext } from "react";
import { apiConnect, tempoApi } from "../services/api"; // Importe a instância configurada do Axios
import { Outlet } from "react-router-dom";

import ensolarado from "../assets/estacao-meteorologica/SolP.svg";
import solCnuvem from "../assets/estacao-meteorologica/solCnuvem.svg";
import nublado from "../assets/estacao-meteorologica/nublado.svg";
import chuva from "../assets/estacao-meteorologica/chuva.svg";
import chuvaEraiosP from "../assets/estacao-meteorologica/chuvaEraiosP.svg";
import neve from "../assets/estacao-meteorologica/neve.svg";
import raios from "../assets/estacao-meteorologica/raios.svg";
import temperatura from "../assets/estacao-meteorologica/temperatura.svg";
import { format } from "date-fns";

export const ClimaContext = createContext();

export const ClimaProvider = () => {
  const [dadosClima, setDadosClima] = useState({});
  const [previsao, setPrevisao] = useState([]);
  const [climaIdSelect, setClimaIdSelect] = useState(null);
  const [listHistorico, setListHistorico] = useState([]);
  const [climaAtual, setClimaAtual] = useState(null);
  const [dateDay, setDateDay] = useState(new Date().getDate());
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [modalCalender, setModalCalender] = useState(false);

  const token = sessionStorage.getItem("connectionsToken");

  const headerApi = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const apiKey = "5ef1c730097b3758da65c159feb9929e";
  const lat = -16.726916111327398;
  const lon = -49.40756980287109;

  // useEffect(() => {
  //   const buscarDataDoTempo = async () => {
  //     try {
  //       const responseAtual = await tempoApi.get(
  //         `weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}&lang=pt_br`
  //       );

  //       setDadosClima(responseAtual.data);

  //       const responsePrevisao = await tempoApi.get(
  //         `forecast?lat=${lat}&lon=${lon}&units=metric&lang=pt_br&appid=${apiKey}`
  //       );
  //       // console.log("Previsão diária:", responsePrevisao.data);
  //       setPrevisao(responsePrevisao.data.list);
  //     } catch (error) {}
  //   };

  //   buscarDataDoTempo();
  // }, []);

  const listHistoricoClima = async (idClimaAtual) => {
    const dataAtual = new Date();
    const dataFormatada = format(dataAtual, "dd-MM-yyyy");

    try {
      const response = await apiConnect.get(
        `meteorologia/historico/${idClimaAtual}/${dataFormatada}`,
        headerApi
      );
      setListHistorico(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const imageMap = {
    "céu limpo": ensolarado,
    "poucas nuvens": solCnuvem,
    "nuvens dispersas": solCnuvem,
    "algumas nuvens": solCnuvem,
    nublado: nublado,
    "nuvens quebradas": nublado,
    "nuvens encobertas": nublado,
    "chuva leve": chuva,
    "chuva moderada": chuva,
    chuva: chuva,
    tempestade: raios,
    neve: neve,
    névoa: solCnuvem,
    "chuva com raios": chuvaEraiosP,
  };

  const selecionarImagemClima = (descricao) => {
    return imageMap[descricao.toLowerCase()];
  };

  return (
    <ClimaContext.Provider
      value={{
        dadosClima,
        previsao,
        selecionarImagemClima,
        climaIdSelect,
        setClimaIdSelect,
        listHistoricoClima,
        listHistorico,
        setListHistorico,
        climaAtual,
        setClimaAtual,
        modalCalender,
        setModalCalender,
        calenderDate,
        setCalenderDate,
        dateDay,
        setDateDay,
      }}
    >
      <Outlet />
    </ClimaContext.Provider>
  );
};
