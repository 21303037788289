import styled from "styled-components";

export const NotFoundStyled = styled.main`
  display: flex;
  align-items:center;
  justify-content:center;
  background-color:#AFF0E4;
  height: 100vh;
  max-height: 100%;
  img{
    width: 40%;
  }
`