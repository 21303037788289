import styled, { css, keyframes } from "styled-components";

const blink = keyframes`
  0% {
      opacity: 1; /* Começa completamente visível */
  }
  50% {
      opacity: 0; /* Fica invisível no meio da animação */
  }
  100% {
      opacity: 1; /* Retorna a ser completamente visível */
  }
  `;

export const rotateAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;
export const reverseRotation = keyframes`
from {
  transform: rotate(360deg);
}
to {
  transform: rotate(0deg);
}
`;

export const PivoController = styled.section`
  width: 96%;
  min-height: 100vh;
  max-height: 100%;
  scrollbar-width: auto;
  h2 {
    margin: 20px 10px;
    font-size: 28px;
    color: rgba(204, 204, 204, 1);
  }
  ul {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
    width: 100%;
    list-style: none;
    padding-right: 10px;
    margin-top: 5px;
    .pivoConteiner {
      display: flex;
      padding-top: 80px;
      width: 100%;
      height: 85vh;
      position: relative;
      background-color: rgba(0, 0, 0, 0.41);
      border-radius: 10px;
      margin-bottom: 20px;
      max-height: 80px;
      border: 1px solid rgba(206, 206, 206, 0.2);
      overflow: hidden;
      gap: 20px;
      max-width: 100%;
      justify-content: center;
      .securityOn {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(160, 0, 0, 1);
        color: rgba(238, 238, 238, 0.91);
        padding: 8px;
        border-radius: 5px;
        font-size: 18px;
        .iconSecurity {
          color: rgba(248, 255, 39, 0.65);
          font-size: 25px;
          animation: ${blink} 8s linear infinite;
        }
      }
      .namePivo {
        display: flex;
        gap: 10px;
        align-items: center;
        position: absolute;
        bottom: 15px;
        left: 25px;
        font-size: 25px;
        color: white;
        h3 {
          margin-right: 20px;
          font-size: 28px;
        }

        width: 100%;
        cursor: pointer;
        .iconePivo {
          font-size: 55px;
          color: white;
        }
        div {
          display: flex;
          align-items: center;
          gap: 15px;
          color: rgba(164, 164, 164, 1);
        }
      }
    }
  }
  li.expanded {
    max-height: 100%;
    padding: 10px;
  }
  .conteinerSection {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-bottom: 75px;
    justify-content: space-between;
    overflow-y: auto;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px;
    color: rgba(207, 207, 207, 0.8);
    border-right: 2px solid rgba(215, 215, 215, 1);
    max-width: 100%;
    h3 {
      display: flex;
      width: 100%;

      justify-content: center;
    }
    ul {
      display: flex;
      list-style: none;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      width: 280px;
      padding-bottom: 15px;
      justify-content: center;
      li {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        p {
          display: flex;
          justify-content: center;
          min-width: 100px;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 30px;
          border: 2px solid rgba(141, 141, 141, 0.8);
          border-radius: 10px;
        }
      }
    }
    .rpmBomb {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      max-width: 100%;
      padding-top: 15px;
      border-top: 2px solid rgba(215, 215, 215, 1);
      .Variables {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 5px;
        input {
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          border: none;
          width: 100px;
          height: 35px;
          color: white;
          max-width: 100%;
          padding-left: 10px;
        }
        button {
          gap: 3px;
          font-size: 16px;
          padding: 5px;
          border: 2px solid rgba(206, 206, 206, 0.4);
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          color: rgba(195, 195, 195, 1);
          cursor: pointer;
          transition: 0.5s;
        }
      }
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* background-color: red; */
    width: 400px;
    gap: 20px;
    max-width: 100%;
    .pivoControle {
      display: flex;
      flex-direction: column;
      gap: 15px;
      ul {
        display: flex;
        flex-direction: row;
        gap: 25px;
        li {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          p {
            display: flex;
            color: rgba(179, 176, 176, 1);
            min-width: 140px;
            justify-content: center;
          }
          button {
            width: 100px;
            height: 42px;
            border-radius: 50px;
            /* background-color: rgba(83, 83, 83, 0.6); */
            cursor: pointer;
            position: relative;
          }
        }
      }
      .liberacao {
        color: rgba(199, 0, 0, 1);
        margin: 0 auto;
      }
    }
    .Percentimetro {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        display: flex;
        color: rgba(179, 176, 176, 1);
        min-width: 140px;
        justify-content: center;
      }
      .Variables {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 5px;
        input {
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          border: none;
          width: 100px;
          height: 35px;
          color: white;
          max-width: 100%;
          padding-left: 10px;
        }
        button {
          gap: 3px;
          font-size: 16px;
          padding: 5px 10px;
          border: 2px solid rgba(206, 206, 206, 0.4);
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          color: rgba(195, 195, 195, 1);
          cursor: pointer;
          transition: 0.5s;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
    color: rgba(207, 207, 207, 0.8);
    border-left: 2px solid rgba(215, 215, 215, 1);
    max-width: 100%;
    h3 {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    ul {
      display: flex;
      list-style: none;
      flex-direction: row;
      justify-content: center;
      gap: 15px;
      flex-wrap: nowrap;
      padding-bottom: 15px;
      max-width: 100%;
      li {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          min-width: 80px;
          height: 30px;
          border: 2px solid rgba(141, 141, 141, 0.8);
          border-radius: 10px;
        }
      }
    }
    .generalData {
      padding-top: 10px;
      border-top: 2px solid rgba(215, 215, 215, 1);
      p {
        min-width: 100px;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 1600px) {
    width: 94%;
  }
  @media (min-width: 1200px) {
    ul {
      .pivoConteiner {
        padding-left: 8%;
        padding-right: 8%;
      }
    }
  }
  @media (max-width: 1100px) {
    width: 92%;
    ul {
      .pivoConteiner {
        padding-left: 0%;
        padding-right: 0%;

        .namePivo {
          font-size: 20px;
          bottom: 20px;
          left: 15px;
          h3 {
            margin-right: 10px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 40px;
            color: white;
          }
        }
        .conteinerSection {
          .left {
            padding: 20px 0;
          }
          .right {
            gap: 0px;
            ul {
              gap: 0;
              /* background-color: beige; */
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    width: 90%;
    ul {
      .pivoConteiner {
        .conteinerSection {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          overflow: auto;
          .center {
            order: 0;
          }
          .left {
            order: 1;
            border-right: none;
            ul {
            }
            .bombData {
              order: 1;
              h3 {
                margin-bottom: 30px;
                width: 100%;
              }
              ul {
                border-bottom: none;
              }
            }
            .rpmBomb {
              border-top: none;
              order: 0;
            }
          }
          .right {
            order: 3;
            border-left: none;
            ul {
              gap: 0;
              /* background-color: beige; */
              font-size: 16px;
            }
            .generalData {
              border-top: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    width: 85%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 15px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 15px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 98%;
    ul {
      .pivoConteiner {
        .namePivo {
          font-size: 25px;
          gap: 5px;
          h3 {
            margin-right: 5px;
            font-size: 25px;
          }
          .iconePivo {
            font-size: 35px;
            color: white;
          }
          div {
            display: flex;
            align-items: center;
            gap: 0px;
            color: rgba(164, 164, 164, 1);
            span {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const PivoInfAnimation = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 10px;
  border: 0px solid white;
  position: relative;
  .borderPivo {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    position: absolute;
    border-radius: 50%;
    top: 0px;
    left: 0px;
    border: 6px dashed
      ${(props) =>
        props.aguaStatus ? "rgba(27, 116, 211, 1)" : "rgba(71, 70, 70, 1)"};
    animation: ${(props) =>
      props.statusPivo &&
      css`
        ${(props) =>
          props.rotationPivo
            ? rotateAnimation
            : reverseRotation} 50s linear infinite
      `};
  }
  .pivoAnimation {
    background-color: ${(props) =>
      props.statusPivo ? "rgba(23, 185, 29, 0.5)" : "rgba(165, 165, 165, 0.5)"};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    .ponteiro {
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, white 50%, transparent 50%);
      transform: rotate(${(props) => props.angle}deg);
      transition: 2s;
      ${({ angle }) =>
        angle >= 0 && angle < 3
          ? css`
              transition: 0s;
            `
          : css`
              transition: 2s;
            `}
    }
    .voltaInicial {
      width: 100%;
      height: 4px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 50%,
        transparent 50%
      );
      transform: rotate(${(props) => props.angleVolta}deg);
      transition: 2s;
      position: absolute;
    }
    .bolinha {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: black;
      border-radius: 50%;
      background-color: white;
    }
  }
`;

export const Dot = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(218, 218, 218, 0.7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 2px;
  transition: 0.5s;
  .icon {
    font-size: 28px;
  }
  .iconloading {
    font-size: 25px;
    animation: ${rotateAnimation} 2s linear infinite;
    cursor: no-drop;
  }
  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 5px;
        `
      : css`
          left: 58px;
        `}
`;

export const ButtonPivo = styled.button`
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 50px;
  border: 1px solid rgba(173, 173, 173, 0.1);
  ${({ status, cor }) =>
    status
      ? css`
          background-color: ${cor};
        `
      : css`
          background-color: rgba(83, 83, 83, 0.6);
        `}
  cursor: pointer;
  position: relative;
`;
