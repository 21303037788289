import { MapMenus, PivosMenu } from "../../styles/Pivo/map";
import pivoIcon from "../../assets/icons/pivot.png";
import { CgCloseO } from "react-icons/cg";
import { PivoContext } from "../../contexts/contexPivos";
import { useContext, useState } from "react";
import { SquareLoading } from "../../components/loaders";
import { PiShieldWarningDuotone, PiStarDuotone } from "react-icons/pi";
import { TbShieldExclamation } from "react-icons/tb";

export const MenuMaps = ({ pivos }) => {
  const {
    menuMap,
    setMenuMap,
    listPivosData,
    moveMapOn,
    handleClimatePivo,
    menuPivoSelect,
    convert4a20,
    locateMap,
  } = useContext(PivoContext);

  const locateFavorite = localStorage.getItem("connectionLocateFav");

  const [SalveLocateFavorite, setSalveLocateFavorite] = useState(
    JSON.parse(locateFavorite)
  );

  const criateFavPivo = (lat, long, pivoId) => {
    if (SalveLocateFavorite !== null) {
      if (pivoId === SalveLocateFavorite.pivoId) {
        setSalveLocateFavorite(null);
        return localStorage.removeItem("connectionLocateFav");
      }
    }
    let setFavorit = {
      longitude: long,
      latitude: lat,
      pivoId: pivoId,
    };
    localStorage.setItem("connectionLocateFav", JSON.stringify(setFavorit));
    setSalveLocateFavorite(setFavorit);
  };

  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;

    var dLon = lon2 - lon1;
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);
    azimuth = azimuth / rad;

    if (azimuth < 0) {
      azimuth += 360;
    }

    return azimuth;
  };

  if (listPivosData[0] === null) {
    return (
      <MapMenus>
        <SquareLoading />
      </MapMenus>
    );
  }

  return (
    <MapMenus>
      <div className="menuHeader">
        <img src={pivoIcon} alt="" />
        <h3>Pivôs</h3>
        <span className="closeMenu" onClick={() => setMenuMap(!menuMap)}>
          <CgCloseO />
        </span>
      </div>
      <ul className="conteinerMenu">
        <li
          className={`allPivos ${moveMapOn ? "" : "allMenuSelect"}`}
          onClick={() => {
            locateMap(-16.89884455899298, -47.80471241108709, 0, 15, "all");
          }}
        >
          Todos Pivôs
        </li>
        {pivos.map((pivo) => {
          const pivoDados = listPivosData.find((e) => pivo.id === e.pivo);

          let latitude_centro = pivoDados ? pivoDados.latitude_centro : null;
          let longitude_centro = pivoDados ? pivoDados.longitude_centro : null;
          let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
          let longitude_ponta = pivoDados ? pivoDados.longitude_ponta : null;

          const angulo = pivoDados
            ? calcAzimuth(
                latitude_centro,
                longitude_centro,
                latitude_ponta,
                longitude_ponta
              )
            : 0;

          return (
            <PivosMenu
              key={pivo.id}
              statusPivo={pivoDados.status_pivo}
              aguaStatus={pivoDados.status_agua}
              rotationPivo={pivoDados.sentido_de_rotacao}
              angle={angulo}
            >
              {pivoDados.seguranca && (
                <TbShieldExclamation className="alertErro" />
              )}
              <h4
                className={`pivosList ${
                  menuPivoSelect && menuPivoSelect.length > 0
                    ? menuPivoSelect[0].id === pivo.id
                      ? "menuSelect"
                      : ""
                    : ""
                }`}
                onClick={() => {
                  locateMap(pivo.latitude, pivo.longitude, 60, 15, pivo.id);
                  // handleClimatePivo(pivo.latitude, pivo.longitude);
                }}
              >
                {pivo.name}
              </h4>
              {SalveLocateFavorite === null ? (
                <span className="Star ">
                  <PiStarDuotone
                    onClick={() =>
                      criateFavPivo(pivo.latitude, pivo.longitude, pivo.id)
                    }
                  />
                </span>
              ) : (
                <span
                  onClick={() =>
                    criateFavPivo(pivo.latitude, pivo.longitude, pivo.id)
                  }
                  className={`Star ${
                    SalveLocateFavorite.pivoId === pivo.id ? "Fav" : ""
                  }`}
                >
                  <PiStarDuotone />
                </span>
              )}
              <div className="pivoInf">
                <div className="pivostatus">
                  <div className="pivoConteiner">
                    <span className="borderPivo"></span>
                    <div className="pivoAnimation">
                      <span className="ponteiro"></span>
                      <span className="bolinha"></span>
                    </div>
                  </div>
                </div>
                <div className="infPivos">
                  <div className="infPivo">
                    <span>
                      {pivoDados
                        ? pivoDados.tensao_r +
                          pivoDados.tensao_s +
                          pivoDados.tensao_t +
                          " V"
                        : "--"}
                    </span>
                    <p>Tensões</p>
                  </div>
                  <div className="infPivo">
                    <span>{pivoDados ? pivoDados.corrente + " A" : "--"}</span>
                    <p>Corrente</p>
                  </div>
                  <div className="infPivo">
                    <span>
                      {pivoDados
                        ? pivoDados.pressao_ponta < 0
                          ? "--"
                          : `${convert4a20(pivoDados.pressao_ponta)} Psi`
                        : "--"}
                    </span>
                    <p>Pressão</p>
                  </div>
                </div>
              </div>
            </PivosMenu>
          );
        })}
      </ul>
    </MapMenus>
  );
};
