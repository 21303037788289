import React, { Suspense, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF, useAnimations } from "@react-three/drei";
import styled from "styled-components";

// Componente de carregamento simples fora do Canvas
const Loader = () => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      zIndex: 1,
    }}
  >
    Loading...
  </div>
);

const StyledCanvas = styled(Canvas)`
  width: 100%;
  height: 100%;
`;

const Model = ({ path }) => {
  const { scene, animations } = useGLTF(path); // Carrega as animações junto com o modelo
  const { actions } = useAnimations(animations, scene); // Hook para controlar animações

  // Inicia a animação principal (ou qualquer outra animação que você desejar)
  useEffect(() => {
    if (actions) {
      actions[Object.keys(actions)[0]].play();
      // Reproduz a primeira animação disponível
    }
  }, [actions]);

  return <primitive object={scene} />;
};

const ThreeDModel = () => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Loader />}
      <StyledCanvas onCreated={() => setLoading(false)}>
        <ambientLight intensity={0.4} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <Suspense fallback={null}>
          <Model path="/models/gltf/AnimatedMorphSphere/glTF/AnimatedMorphSphere.gltf" />
        </Suspense>
        <OrbitControls />
      </StyledCanvas>
    </>
  );
};

export default ThreeDModel;
