import styled from "styled-components";
import imgBg from "../assets/imgs-background/background1.png";

export const LoginMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LoginFormaStyled = styled.form`
  display: flex;
  max-width: 80%;
  width: 380px;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 23, 0.25);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 25px 40px;
  gap: 20px;
  z-index: 2;
  h1 {
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 5px;
  }
  img {
    width: 80%;
    max-width: 85%;
    margin-bottom: 20px;
  }
  .Inpults {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    input {
      border-radius: 3px;
      border: none;
      outline: none;
      max-width: 100%;
      height: 30px;
      background: rgba(145, 145, 145, 0.4);
      color: white;
      padding-left: 10px;
      &::placeholder {
        color: #b2b2b2;
      }
    }
    .iconLogin {
      display: flex;
      align-items: center;
      gap: 5px;
      position: absolute;
      right: 10px;
      color: #b2b2b2;
      height: 30px;
      .viewPassword {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .buttons {
    width: 100%;
    button {
      width: 100%;
      border: none;
      padding: 7px;
      background: rgba(145, 145, 145, 0.5);
      color: #d6d5d5;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: 0.5s;
    }
    button:hover {
      color: white;
    }
  }
  .Copyright {
    color: #808080;
    margin-bottom: 45px;
  }
  .formFooter {
    width: 100%;
    border: 1px solid;
    color: #808080;
  }
`;
