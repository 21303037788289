import { useContext, useEffect, useRef, useState } from "react";
import { StyledMapGl, UserLoc } from "../styles/Pivo/map";
import { Layer, Marker, Source } from "react-map-gl";
import { PivoContext } from "../contexts/contexPivos";
import { HomeContext } from "../contexts/contexHome";

const skyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [0.0, 0.0],
    "sky-atmosphere-sun-intensity": 15,
  },
};

// Camada para renderizar edifícios em 3D com simulação de sombras
const buildingLayer = {
  id: "3d-buildings",
  source: "composite",
  "source-layer": "building",
  filter: ["==", "extrude", "true"],
  type: "fill-extrusion",
  minzoom: 15,
  paint: {
    "fill-extrusion-color": "#aaa",
    "fill-extrusion-height": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "height"],
    ],
    "fill-extrusion-base": [
      "interpolate",
      ["linear"],
      ["zoom"],
      15,
      0,
      15.05,
      ["get", "min_height"],
    ],
    "fill-extrusion-opacity": 0.85,
    // Simulação de sombra: uso de oclusão ambiente e variação de cor
    "fill-extrusion-ambient-occlusion-intensity": 0.7, // Intensidade da sombra
    "fill-extrusion-translate": [10, 10], // Deslocamento para criar efeito de sombra
    "fill-extrusion-ambient-occlusion-radius": 5, // Raio da sombra
    "fill-extrusion-vertical-gradient": false, // Ativa gradiente vertical para simular iluminação superior
    "fill-extrusion-lighting-direction": 180, // Direção da luz para simular a sombra em um lado específico
    "fill-extrusion-lighting-ambient": 0.1, // Iluminação ambiente
    "fill-extrusion-lighting-diffuse": 0.1, // Iluminação difusa para sombras
    "fill-extrusion-shadow-color": "#000000", // Cor personalizada para sombra
  },
};

export const Map3d = () => {
  const { cameraState, setCameraState } = useContext(PivoContext);
  const mapRef = useRef();
  const { user } = useContext(HomeContext);

  const [viewport, setViewport] = useState({
    longitude: cameraState.center.lng,
    latitude: cameraState.center.lat,
    zoom: cameraState.zoom,
    pitch: 60, // Inclinação da câmera para visualizar os edifícios 3D
    bearing: 0, // Direção inicial da câmera
    minZoom: 1,
    maxZoom: 18,
  });

  const handleViewportMove = (newViewport) => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      longitude: newViewport.longitude,
      latitude: newViewport.latitude,
      zoom: newViewport.zoom,
      pitch: newViewport.pitch,
      bearing: newViewport.bearing,
    }));
  };

  useEffect(() => {
    setViewport({
      longitude: cameraState.center.lng,
      latitude: cameraState.center.lat,
      zoom: cameraState.zoom,
      pitch: 60,
      bearing: 0,
      minZoom: 1,
      maxZoom: 18,
    });
  }, [cameraState]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.on("style.load", () => {
        map.setFog({
          color: "rgba(117, 110, 65, 0.475)",
          "high-color": "rgba(36, 151, 223, 0.473)",
          "horizon-blend": 0.02,
          "space-color": "rgb(28, 25, 25)",
          "star-intensity": 0.6,
        });
      });
    }
  }, [mapRef.current]);

  return (
    <StyledMapGl
      ref={mapRef}
      {...viewport}
      onMove={(e) => handleViewportMove(e.viewState)}
      mapboxAccessToken="pk.eyJ1Ijoia3puaWNrIiwiYSI6ImNscmdmNno4eDAzNWsycnBqNDFpMTBmZmgifQ.V0My2LfHP1945deYhDTinA"
      mapStyle={"mapbox://styles/mapbox/satellite-streets-v12"}
      terrain={{ source: "mapbox-dem", exaggeration: 2.3 }} // Exageração do terreno para visualização mais clara
    >
      {user.longitude && (
        <Marker longitude={user.longitude} latitude={user.latitude}>
          <UserLoc />
        </Marker>
      )}
      <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.mapbox-terrain-dem-v1"
        tileSize={512}
        maxzoom={18}
      />
      <Layer {...skyLayer} />
      <Layer {...buildingLayer} /> {/* Camada de edifícios 3D com sombras */}
    </StyledMapGl>
  );
};
