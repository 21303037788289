import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const ProgressContainer = styled.div`
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
  color: rgba(215, 215, 215, 1);
  font-size: 14px;
  /* background-color: red; */
  margin-bottom: 10px;
`;

const BarOverflow = styled.div`
  position: relative;
  overflow: hidden;
  width: 140px;
  height: 70px;
  margin-bottom: -14px;
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 10px solid #9a9a9ae8;
  border-bottom-color: #3c3c3ce8;
  border-right-color: #3c3c3ce8;
`;

const ProgressText = styled.span`
  position: relative;
  z-index: 1;
  color: rgba(215, 215, 215, 1);
  font-size: 18px;
`;

const ProgressBar = ({ min, max, value, tipo }) => {
  const barRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const $bar = barRef.current;
    const $text = textRef.current;
    const duration = 3000;
    const easing = "swing";
    const perc = ((value - min) / (max - min)) * 100;

    let startTime;

    const animateBar = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const p = Math.floor(progress * perc);
      const rotation = 45 + p * 1.8;

      $bar.style.transform = `rotate(${rotation}deg)`;
      $text.textContent = value; // Exibir apenas o valor passado

      if (progress < 1) {
        requestAnimationFrame(animateBar);
      }
    };

    requestAnimationFrame(animateBar);

    return () => {
      startTime = null;
    };
  }, [min, max, value]);

  return (
    <ProgressContainer>
      <BarOverflow>
        <Bar ref={barRef} />
      </BarOverflow>
      <ProgressText ref={textRef} /> {tipo}
    </ProgressContainer>
  );
};

export default ProgressBar;
