import { useContext, useState } from "react";
import {
  ConfigPivoStyled,
  InputsStyled,
  VariablesStyled,
} from "../../styles/Pivo/configPivo";
import { PivoContext } from "../../contexts/contexPivos";
import { TfiArrowCircleUp } from "react-icons/tfi";
import { GiAnticlockwiseRotation, GiClockwiseRotation } from "react-icons/gi";
import { FaDroplet, FaDropletSlash } from "react-icons/fa6";
import { LuPowerOff } from "react-icons/lu";
import { SiPivotaltracker } from "react-icons/si";
import { PivoInfAnimation } from "../../styles/Pivo/pivoControler";
import imgCirculo360 from "../../assets/icons/Circulo0_360BrancoCB.png";
import { BiSolidAddToQueue } from "react-icons/bi";
import { SquareLoading } from "../../components/loaders";
import { MdDeleteSweep } from "react-icons/md";
import { apiConnect } from "../../services/api";
import { toast } from "react-toastify";
import { BsFillTrashFill } from "react-icons/bs";
import { ModalConfirmController } from "../../styles/modals";

export const ConfigPivo = ({ pivoComponente }) => {
  const {
    expandedPivos,
    setExpandedPivos,
    listPivosData,
    headerApi,
    modalTaxaVariable,
    setModalTaxaVariable,
  } = useContext(PivoContext);

  const [newVariable, setNewVariable] = useState({});
  const [SalvesId, setSalvesId] = useState([]);

  const toggleItemExpansion = (itemId) => {
    setExpandedPivos((prevExpandedItems) =>
      prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((id) => id !== itemId)
        : [...prevExpandedItems, itemId]
    );
  };

  const calcAzimuth = (lat1, lon1, lat2, lon2) => {
    // Converter graus para radianos
    var rad = Math.PI / 180;
    lat1 *= rad;
    lat2 *= rad;
    lon1 *= rad;
    lon2 *= rad;

    // Diferença de longitude
    var dLon = lon2 - lon1;

    // Cálculo do azimute
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var azimuth = Math.atan2(y, x);

    // Converter de radianos para graus
    azimuth = azimuth / rad;

    // Ajuste para valores entre 0 e 360 graus
    if (azimuth < 0) {
      azimuth += 360;
    }

    return azimuth;
  };

  function generateUniqueNumber() {
    // Current timestamp in milliseconds
    const timestamp = Date.now();

    // Random number between 0 and 9999
    const randomNum = Math.floor(Math.random() * 10000);

    // Combine timestamp and random number
    const uniqueString = `${timestamp}${randomNum}`;

    // Convert the combined string to a number
    const uniqueNumber = parseInt(uniqueString);

    return uniqueNumber;
  }

  function getRandomColorRGBA() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = 0.8;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  if (pivoComponente.length > 0) {
    if (listPivosData.length === 0) {
      return (
        <ConfigPivoStyled>
          <SquareLoading />
        </ConfigPivoStyled>
      );
    }
  }

  const handleDelete = (pivoId, variableId) => {
    setNewVariable((prevState) => {
      const updatedVariables = prevState[pivoId].filter(
        (item) => item.id !== variableId
      );
      return {
        ...prevState,
        [pivoId]: updatedVariables,
      };
    });
  };

  const updateVariavel = async (pivoDataId, pivoId) => {
    try {
      const response = await apiConnect.get(
        `dados/pivo/Leitura/${pivoDataId}`,
        headerApi
      );

      let taxasVariables = response.data.taxas_variables;

      if (taxasVariables === "" || taxasVariables === "[]") {
        taxasVariables = [];
      } else {
        taxasVariables = JSON.parse(taxasVariables);
      }

      let existe = false;

      if (newVariable[pivoId]) {
        taxasVariables.push(...newVariable[pivoId]);
      }

      const pivoDados = listPivosData.find((e) => pivoId === e.pivo);

      let taxaVariable = response.data.taxas_variables;

      if (taxaVariable === "" || taxaVariable === "[]") {
        taxaVariable = [];
      } else {
        taxaVariable = JSON.parse(taxaVariable);
      }

      newVariable[pivoId].map((e) => {
        taxaVariable.map((dados) => {
          if (
            Number(e.angulo) >= Number(dados.angulo) - 3 &&
            Number(e.angulo) <= Number(dados.angulo) + 3
          ) {
            existe = true;
          }
        });
      });

      // const filteredTaxasVariables = taxasVariables.filter(
      //   (variables) => delete variables.cor
      // );

      const updatedTaxasVariables = JSON.stringify(taxasVariables);

      if (existe === true) {
        return toast.warn(
          "A taxa variável deve ser diferente e ter 4 graus de diferença entre os ângulos.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        await apiConnect.patch(
          `dados/pivo/${pivoDataId}`,
          {
            taxas_variables: updatedTaxasVariables,
            horario: response.data.horario,
            data: response.data.data,
          },
          headerApi
        );
        setNewVariable((prevState) => ({
          ...prevState,
          [pivoId]: [],
        }));
        toast.success("Taxa variáveis criadas", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <ConfigPivoStyled>
      {modalTaxaVariable && (
        <MOdalConfirmPivoVariables
          IdDataPivo={SalvesId[0]}
          IdTaxa={SalvesId[1]}
        />
      )}
      <h2>Configurações</h2>
      <ul>
        {pivoComponente.map((pivo) => {
          const isExpanded = expandedPivos.includes(pivo.id);
          const pivoDados = listPivosData.find((e) => pivo.id === e.pivo);

          let taxaVariable = pivoDados.taxas_variables;

          if (taxaVariable === "" || taxaVariable === "[]") {
            taxaVariable = [];
          } else {
            taxaVariable = JSON.parse(taxaVariable);
          }

          taxaVariable.sort((a, b) => {
            return a.angulo - b.angulo;
          });

          let latitude_centro = pivoDados ? pivoDados.latitude_centro : null;
          let longitude_centro = pivoDados ? pivoDados.longitude_centro : null;
          let latitude_ponta = pivoDados ? pivoDados.latitude_ponta : null;
          let longitude_ponta = pivoDados ? pivoDados.longitude_ponta : null;

          let angulo = calcAzimuth(
            latitude_centro,
            longitude_centro,
            latitude_ponta,
            longitude_ponta
          );

          if (pivo.maquete === true) {
            if (pivoDados && pivoDados.rotacao_maquete !== undefined) {
              angulo = pivoDados.rotacao_maquete;
            } else {
              angulo = 0; // ou algum valor padrão
            }
          }
          const handleInputChange = (pivoId, id, field, value) => {
            if (value > 359) {
              value = 359;
            }
            if (field === "percetimetro" && value > 100) {
              value = 100;
            }
            if (field === "percetimetro" && value < 0) {
              value = 0;
            }
            setNewVariable((prevState) => {
              const updatedPivoVariables = prevState[pivoId].map((item) =>
                item.id === id ? { ...item, [field]: value } : item
              );
              return {
                ...prevState,
                [pivoId]: updatedPivoVariables,
              };
            });
          };

          const isEmpty = (obj) => {
            return Object.keys(obj).length === 0;
          };

          return (
            <li
              key={pivo.id}
              className={`pivoConteiner ${isExpanded ? "expanded" : ""}`}
            >
              <div
                className="namePivo"
                onClick={() => toggleItemExpansion(pivo.id)}
              >
                <SiPivotaltracker className="iconePivo" />
                <h3>{pivo.name}</h3>
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_pivo ? "Ligado" : "Desligado"}
                    </span>
                    {pivoDados.status_pivo ? <LuPowerOff /> : <LuPowerOff />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      | {pivoDados.status_agua ? "Irrigando" : "Seco"}
                    </span>
                    {pivoDados.status_agua ? <FaDroplet /> : <FaDropletSlash />}
                  </div>
                )}
                {!isExpanded && (
                  <div>
                    <span>
                      |
                      {pivoDados.sentido_de_rotacao
                        ? "Horário"
                        : "Anti-horário"}
                    </span>
                    {pivoDados.sentido_de_rotacao ? (
                      <GiClockwiseRotation />
                    ) : (
                      <GiAnticlockwiseRotation />
                    )}
                  </div>
                )}
                {isExpanded && <TfiArrowCircleUp />}
              </div>

              <div className="sections">
                <section className="left">
                  <div className="pivo">
                    <PivoInfAnimation
                      statusPivo={pivoDados.status_pivo}
                      aguaStatus={pivoDados.status_agua}
                      rotationPivo={pivoDados.sentido_de_rotacao}
                      angle={angulo}
                      angleVolta={pivoDados.volta_inicial}
                      className="pivoCirculo"
                    >
                      <span className="borderPivo"></span>
                      <div className="pivoAnimation">
                        <span className="ponteiro"></span>
                        {pivoDados.limite_de_voltas && (
                          <span className="voltaInicial"></span>
                        )}
                        <span className="bolinha"></span>
                      </div>
                    </PivoInfAnimation>
                    <img className="circulo" src={imgCirculo360} alt="" />

                    {Object.keys(newVariable).map((key) => {
                      if (Number(key) === pivo.id) {
                        return newVariable[key].map((elemento) => {
                          let anguloo = Number(elemento.angulo);
                          return (
                            <VariablesStyled
                              key={elemento.id}
                              angle={anguloo}
                              cor={elemento.cor}
                            />
                          );
                        });
                      }
                      return null;
                    })}

                    {taxaVariable.map((variavel) => {
                      return (
                        <VariablesStyled
                          key={variavel.id}
                          angle={Number(variavel.angulo)}
                          cor={"rgba(223, 223, 223, 0.45)"}
                        />
                      );
                    })}
                  </div>
                </section>
                <section className="center">
                  <div className="icons">
                    <BiSolidAddToQueue
                      className="icon"
                      onClick={() => {
                        if (newVariable[pivo.id]) {
                          if (
                            newVariable[pivo.id].length + taxaVariable.length >=
                            12
                          ) {
                            return toast.warn(
                              "Limite de Taxa variáveis excedido",
                              {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              }
                            );
                          }
                        }

                        const newDataVariavel = {
                          id: generateUniqueNumber(), // Novo ID único
                          percetimetro: 100,
                          angulo: 0,
                          cor: getRandomColorRGBA(),
                        };

                        setNewVariable((prevState) => ({
                          ...prevState,
                          [pivo.id]: [
                            ...(prevState[pivo.id] || []),
                            newDataVariavel,
                          ],
                        }));
                      }}
                    />
                  </div>
                  {isEmpty(newVariable) ? (
                    <h3>Cria Uma nova Taxa variável</h3>
                  ) : (
                    <ul className="listCratVariable">
                      {Object.keys(newVariable).map((key) => {
                        if (Number(key) === pivo.id) {
                          return newVariable[key].length > 0 ? (
                            newVariable[key].map((variable) => {
                              return (
                                <InputsStyled cor={variable.cor}>
                                  <div className="inputs">
                                    <label
                                      htmlFor={`percetimetro-${variable.id}`}
                                    >
                                      Percentimetro
                                    </label>
                                    <input
                                      type="number"
                                      id={`percetimetro-${variable.id}`}
                                      min={0}
                                      max={100}
                                      value={variable.percetimetro}
                                      onChange={(e) =>
                                        handleInputChange(
                                          pivo.id,
                                          variable.id,
                                          "percetimetro",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="inputs">
                                    <label htmlFor={`angulo-${variable.id}`}>
                                      Ângulo
                                    </label>
                                    <input
                                      type="number"
                                      id={`angulo-${variable.id}`}
                                      min={0}
                                      max={360}
                                      value={variable.angulo}
                                      onChange={(e) =>
                                        handleInputChange(
                                          pivo.id,
                                          variable.id,
                                          "angulo",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <span
                                    className="iconss"
                                    onClick={() =>
                                      handleDelete(Number(key), variable.id)
                                    }
                                  >
                                    <MdDeleteSweep className="delete" />
                                  </span>
                                </InputsStyled>
                              );
                            })
                          ) : (
                            <h3>Cria Uma nova Taxa variável</h3>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  )}
                  {isExpanded && (
                    <div className="Buttoes">
                      {newVariable[pivo.id] && (
                        <button
                          onClick={() => updateVariavel(pivoDados.id, pivo.id)}
                        >
                          Salvar
                        </button>
                      )}
                    </div>
                  )}
                </section>
                <section className="right">
                  <h3>Taxa variáveis</h3>
                  <ul>
                    {taxaVariable.length > 0 ? (
                      taxaVariable.map((variavel) => {
                        return (
                          <li>
                            <div className="Info">
                              <p>Ângulo</p>
                              <span>{variavel.angulo}</span>
                            </div>
                            <div className="Info">
                              <p>Percentimetro</p>
                              <span>{variavel.percetimetro}</span>
                            </div>
                            <div className="icons">
                              <BsFillTrashFill
                                className="icon"
                                onClick={() => {
                                  setSalvesId([pivoDados.id, variavel.id]);
                                  setModalTaxaVariable(true);
                                }}
                              />
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div>
                        <h4>Não existe nenhuma variável</h4>
                      </div>
                    )}
                  </ul>
                </section>
              </div>
            </li>
          );
        })}
      </ul>
    </ConfigPivoStyled>
  );
};

export const MOdalConfirmPivoVariables = ({ IdDataPivo, IdTaxa }) => {
  const { headerApi, setModalTaxaVariable } = useContext(PivoContext);

  const deleteVariable = async (pivoDataId, variableId) => {
    try {
      const response = await apiConnect.get(
        `dados/pivo/Leitura/${pivoDataId}`,
        headerApi
      );

      let taxasVariables = response.data.taxas_variables;

      if (taxasVariables === "" || taxasVariables === "[]") {
        taxasVariables = [];
      } else {
        taxasVariables = JSON.parse(taxasVariables);
      }

      const deleteTaxaVariable = taxasVariables.filter(
        (e) => e.id !== variableId
      );
      const updatedTaxasVariables = JSON.stringify(deleteTaxaVariable);

      await apiConnect.patch(
        `dados/pivo/${pivoDataId}`,
        {
          taxas_variables: updatedTaxasVariables,
          horario: response.data.horario,
          data: response.data.data,
        },
        headerApi
      );
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Deseja Deletar ?`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              deleteVariable(IdDataPivo, IdTaxa);
              setModalTaxaVariable(false);
            }}
          >
            Sim
          </button>
          <button onClick={() => setModalTaxaVariable(false)}>Não</button>
        </div>
      </div>
    </ModalConfirmController>
  );
};
