import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { GraficoVentoStyled } from "../../styles/meteorologia/containerVento";
import { ClimaContext } from "../../contexts/contexClima";
import { ClimaAtualStyled } from "../../styles/meteorologia/tempoAtual";
import { SquareLoading } from "../../components/loaders";
import { format, parseISO } from "date-fns";

export const GraficoDoVento = () => {
  // Registro dos componentes necessários para o gráfico
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );

  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };

  const { climaIdSelect, listHistoricoClima, listHistorico } =
    useContext(ClimaContext);

  const [historicoVento, setHistoricoVento] = useState(listHistorico);

  if (!climaIdSelect) {
    <ClimaAtualStyled>
      <SquareLoading />
    </ClimaAtualStyled>;
  }

  useEffect(() => {
    listHistoricoClima(climaIdSelect);
  }, []);

  useEffect(() => {
    setHistoricoVento(listHistorico);
  }, [listHistorico]);

  // Configuração dos dados para o gráfico
  const data = {
    labels: historicoVento.map((data) =>
      format(parseISO(data.changedAt), "HH:mm")
    ),
    datasets: [
      {
        label: "Velocidade do Vento",
        data: historicoVento.map((data) => (data.wind_speed * 3.6).toFixed(1)),
        backgroundColor: "#ffffff3e",
        borderColor: "#ffffff",
        pointBackgroundColor: "#c6c6c6",
        pointRadius: 2,
        color: "#ffffff",
        tension: 0.3,
        fill: true,
      },
    ],
  };

  // Configuração das opções do gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo x
          font: {
            size: 11, // Tamanho da fonte das labels no eixo x
          },
        },
        grid: {
          color: "#61606097", // Cor das linhas de grade do eixo X
        },
      },
      y: {
        min: 0,
        suggestedMax: 60,
        beginAtZero: true,
        ticks: {
          color: "#e7e0e0", // Cor do texto no eixo y
        },
        grid: {
          color: "#61606097", // Cor das linhas de grade do eixo X
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 1, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      zoom: zoomOptions,
    },
  };

  return (
    <GraficoVentoStyled>
      <div className="graficoVento">
        <Line data={data} options={options} />
      </div>
    </GraficoVentoStyled>
  );
};
