import { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiConnect } from "../services/api";
import { HomeContext } from "./contexHome";

export const AdminContext = createContext({});

export const AdminProvider = () => {
  const { userOn, attUser } = useContext(HomeContext);
  const navigate = useNavigate();

  const [adminPage, setAdminPage] = useState("User");
  const [usersSave, setUsersSave] = useState([]);
  const [pivosList, setPivosList] = useState([]);
  const [modalPivoConfirmation, setModalPivoConfirmation] = useState(false);
  const [modalConfimation, setModalConfimation] = useState(false);
  const [modalPivoDeletConfirmation, setModalPivoDeletConfirmation] =
    useState(false);
  const [modalUserDeletConfirmation, setModalUserDeletConfirmation] =
    useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modaUpdatePassword, setModalUpdatePassword] = useState(false);
  const [modalCreatingPivo, setModalCreatingPivo] = useState(false);
  const [modalCreatingUser, setModalCreatingUser] = useState(false);
  const [modalMedidores, setModalMedidores] = useState(false);
  const [modalBombaTanque, setModalBombaTaque] = useState(false);
  const [salvePivoInfo, setSalvePivoInfo] = useState([]);
  const [salveTanqueInfo, setSalveTanqueInfo] = useState([]);
  const [salvePivoId, setSavePivoId] = useState(0);
  const [salveUserId, setSaveUserId] = useState(0);
  const [medidoresList, setMedidoreslist] = useState([]);
  const [tanquesList, setTanquesList] = useState([]);
  const [modalCreatTanque, setModalCreatTanque] = useState(false);
  const [modalHistoricoGW, setModalHistoricoGW] = useState(false);

  const token = sessionStorage.getItem("connectionsToken");
  const headerApi = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const uservalidation = async () => {
    try {
      const api = await apiConnect.get("users/leituratodos", headerApi);
      setUsersSave(api.data);
    } catch (error) {
      toast.warn("🤐Algo de Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleListPivos = async () => {
    try {
      const response = await apiConnect.get("pivos/", headerApi);
      setPivosList(response.data);
    } catch (error) {
    } finally {
    }
  };

  const handleUpdateControllerPivo = async (id, dataPivo) => {
    try {
      dataPivo.tamanho = parseInt(dataPivo.tamanho);
      dataPivo.latitude = Number(dataPivo.latitude);
      dataPivo.longitude = Number(dataPivo.longitude);
      delete dataPivo.unidade;
      const response = await apiConnect.patch(
        `pivos/${id}/`,
        dataPivo,
        headerApi
      );
      toast.success("Alteraçoes feitas com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      const dadosPivo = await apiConnect.get("pivos/", headerApi);
      setPivosList(dadosPivo.data);
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setModalPivoConfirmation(!modalPivoConfirmation);
    }
  };

  const deletePivo = async (id) => {
    try {
      const PivoDelete = await apiConnect.delete(`pivos/${id}/`, headerApi);
      const response = await apiConnect.get("pivos/", headerApi);
      setPivosList(response.data);
      toast.success("Pivô Deletado com Sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setModalPivoDeletConfirmation(!modalPivoDeletConfirmation);
    }
  };

  const deleteUser = async (id) => {
    try {
      const UserDelete = await apiConnect.delete(`users/${id}/`, headerApi);
      toast.success("Usuario Deletado com Sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("🤐Algo de Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setModalUserDeletConfirmation(!modalUserDeletConfirmation);
    }
  };

  const handlePivotCreation = async (data) => {
    try {
      const PivoCreation = await apiConnect.post(`pivos/`, data, headerApi);

      const idPivo = {
        pivo: PivoCreation.data.id,
      };
      const PivoDataCreation = await apiConnect.post(
        `dados/pivo/`,
        idPivo,
        headerApi
      );
      const PivoControllerCreation = await apiConnect.post(
        `controle/pivo/`,
        idPivo,
        headerApi
      );
      const MotoBombaControllerCreation = await apiConnect.post(
        `controle/motobomba/`,
        idPivo,
        headerApi
      );
      const MotoBombaDadosCreation = await apiConnect.post(
        `dados/motobomba/`,
        idPivo,
        headerApi
      );
      const eletrobombaDadosCreation = await apiConnect.post(
        `dados/eletrobomba/`,
        idPivo,
        headerApi
      );
      const eletrobombaControllerCreation = await apiConnect.post(
        `controle/eletrobomba/`,
        idPivo,
        headerApi
      );
      const response = await apiConnect.get("pivos/", headerApi);
      setPivosList(response.data);
      toast.success("Pivô Criado com Sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      if (error.response.status === 409) {
        toast.warn("Pivô com esse nome já existe", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warn("Falha", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } finally {
      setModalCreatingPivo(false);
    }
  };

  const handleUsertCreation = async (data) => {
    try {
      const UserCreation = await apiConnect.post(`users/`, data, headerApi);
      toast.success("Novo usuario criado com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setModalCreatingUser(!modalCreatingUser);
    } catch (error) {
      if (error.response.status === 409) {
        if (error.response.data.message === "Username already exists") {
          toast.warn("Usuário já cadastrado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else
        toast.warn("Algo de Errado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    } finally {
    }
  };

  const handleUserUpdate = async (data) => {
    try {
      const user = await apiConnect.patch(
        `users/${salveUserId}/`,
        data,
        headerApi
      );
      toast.success("Alterações Salvas com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setModalEditUser(false);
      setModalUpdatePassword(false);
    } catch (error) {
      if (error.response.status === 409) {
        if (error.response.data.message === "Username already exists") {
          toast.warn("Usuário já cadastrado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else
        toast.warn("🤐Algo de Errado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    } finally {
    }
  };

  const ListTanques = async () => {
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("tanques/LeituraTodos", headerApi);
      setTanquesList(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!token) {
      sessionStorage.clear();
      navigate("/login");
    }
    uservalidation();
    handleListPivos();
  }, []);

  useEffect(() => {
    setUsersSave((usersSave) =>
      usersSave.map((user) =>
        user.id === userOn.id ? { ...user, online: userOn.status } : user
      )
    );
  }, [userOn]);

  useEffect(() => {
    if (attUser[0] === "Criado") {
      setUsersSave((prevUsers) => [...prevUsers, attUser[1]]);
    }
    if (attUser[0] === "Edit") {
      setUsersSave((usersSave) =>
        usersSave.map((user) =>
          user.id === attUser[1].id ? { ...user, ...attUser[1] } : user
        )
      );
    }
    if (attUser[0] === "Deletado") {
      setUsersSave((prevUsers) =>
        prevUsers.filter((user) => user.id !== attUser[1])
      );
    }
  }, [attUser]);

  return (
    <AdminContext.Provider
      value={{
        adminPage,
        setAdminPage,
        usersSave,
        pivosList,
        modalPivoConfirmation,
        setModalPivoConfirmation,
        headerApi,
        setSalvePivoInfo,
        salvePivoInfo,
        handleUpdateControllerPivo,
        modalPivoDeletConfirmation,
        setModalPivoDeletConfirmation,
        salvePivoId,
        setSavePivoId,
        deletePivo,
        modalCreatingPivo,
        setModalCreatingPivo,
        handlePivotCreation,
        modalCreatingUser,
        setModalCreatingUser,
        handleUsertCreation,
        modalUserDeletConfirmation,
        setModalUserDeletConfirmation,
        salveUserId,
        setSaveUserId,
        deleteUser,
        modaUpdatePassword,
        setModalUpdatePassword,
        handleUserUpdate,
        modalEditUser,
        setModalEditUser,
        modalConfimation,
        setModalConfimation,
        salveTanqueInfo,
        setSalveTanqueInfo,
        modalMedidores,
        setModalMedidores,
        medidoresList,
        setMedidoreslist,
        ListTanques,
        tanquesList,
        setTanquesList,
        modalBombaTanque,
        setModalBombaTaque,
        modalCreatTanque,
        setModalCreatTanque,
        modalHistoricoGW,
        setModalHistoricoGW,
      }}
    >
      <Outlet />
    </AdminContext.Provider>
  );
};
