import { useContext, useEffect, useState } from "react";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { HomeContext } from "../../contexts/contexHome";
import { ModalHeader } from "../../styles/header";
import { HomeMain } from "../../styles/home";
import { apiConnect } from "../../services/api";
import { SquareLoading } from "../../components/loaders";

export const Home = () => {
  const { navigate, isOpen, setIsOpen } = useContext(HomeContext);

  const unityExists = sessionStorage.getItem("connectionsUnit");

  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);
  const [checkingPermission, setCheckingPermission] = useState(true);
  const [locationUser, setLocationUser] = useState(null);

  const getLocationPermission = () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        reject(new Error("Geolocation not supported"));
      }
    });
  };

  useEffect(() => {
    if (unityExists) {
      navigate(`/${unityExists}`);
    }
    ListUnidades();
  }, []);

  const saveUnit = (unit, id) => {
    sessionStorage.setItem("connectionsUnit", unit);
    sessionStorage.setItem("connectionsUnitId", id);
    navigate(`/${unit}`);
  };

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setloading(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open"); // Adiciona a classe overflow-hidden ao body quando o modal é aberto
    } else {
      document.body.classList.remove("modal-open"); // Remove a classe overflow-hidden do body quando o modal é fechado
    }

    // Limpa a classe quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const location = await getLocationPermission();
        setLocationUser(location);
        setCheckingPermission(false); // Permissão concedida
      } catch (err) {
        console.log("Sem permissão LOC");
        setTimeout(checkLocationPermission, 5000); // Tenta novamente em 5 segundos
      }
    };

    checkLocationPermission();
  }, []);

  return (
    <HomeMain>
      {/* {checkingPermission && <div>Permiteeee</div>} */}
      {loading && <SquareLoading />}
      <Header relative={"true"} />
      {isOpen ? <ModalHeader onClick={() => setIsOpen(!isOpen)} /> : null}
      <div className="container">
        <h2>Sistema de Gestão</h2>
        <p>Selecione uma Unidade Abaixo</p>
        <div className="containerButtons">
          {Unidades.map((unidade) => {
            return (
              <div className="buttons" key={unidade.id}>
                <button onClick={() => saveUnit(unidade.url, unidade.id)}>
                  {unidade.name}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <Footer relative={"true"} />
    </HomeMain>
  );
};
