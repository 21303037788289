import styled from "styled-components";
import { css } from "styled-components";
import imgBg from "../assets/imgs-background/background1.png";

export const AdminPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  nav {
    width: 80%;
    /* max-width: 90%; */
    margin-top: 35px;
    height: 45px;
    position: relative;
    overflow: auto;
    /* background: red; */
    ul {
      display: flex;
      gap: 40px;
      max-width: 100%;
      list-style: none;
      /* background: red; */
      height: 100%;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: white;
        cursor: pointer;
        width: 50px;
        height: 100%;
        z-index: 2;
        max-width: 100%;
        img {
          width: 30px;
        }
      }
    }
  }
  .nav-list {
    position: relative;
    display: flex;
    list-style-type: none;
  }

  .nav-item {
    cursor: pointer;
    padding: 10px;
  }

  .nav-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 45px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px 20px 0px 0px;
    transition: width 0.3s, left 0.3s;
    z-index: 1;
  }

  .active {
    /* background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.45);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px 20px 0px 0px; */
  }
  .active .nav-indicator {
    width: 100%; /* largura total */
  }
  .disabled {
    opacity: 40%;
    cursor: not-allowed;
  }

  .construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 90%;
    background: red;
    height: 100%;
    min-height: 85vh;
    margin-left: 70px;
    margin-bottom: 100px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    padding: 25px;
    img {
      width: 50%;
    }
  }
  @media (max-width: 800px) {
    nav {
      width: 65%;
    }
  }
  @media (max-width: 400px) {
    nav {
      width: 55%;
    }
    .teste {
      img {
        width: 90%;
      }
    }
  }
`;

export const UserAdmin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  background: red;
  height: 100%;
  min-height: 85vh;
  margin-left: 70px;
  margin-bottom: 100px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 25px;
  .Header {
    display: flex;
    gap: 25px;
    align-items: center;
    h2 {
      font-size: 30px;
      color: white;
    }

    .addIcon {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 30px;
      cursor: pointer;
      color: white;
      position: relative;
      span {
        display: none;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.3);
        padding: 5px 15px;
        border-radius: 13px;
        opacity: 60%;
        -webkit-animation: scale-in-hor-left 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }
  ul {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    width: 90%;
    height: 100%;
    gap: 25px;
    list-style: none;
    /* background-color: aquamarine; */
    margin: 0 auto;
    li {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 350px;
      min-height: 40px;
      max-width: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      box-shadow: 0 0px 30px 0 rgba(232, 232, 232, 0.2);
      padding: 15px;
      color: rgba(203, 203, 203, 1);
      padding-top: 50px;
      position: relative;
      .iconEdit {
        position: absolute;
        top: 10px;
        right: 50px;
        font-size: 25px;
        cursor: pointer;
      }
      .iconBin {
        position: absolute;
        top: 12px;
        right: 20px;
        font-size: 22px;
        cursor: pointer;
        transition: 0.5s;
      }
      .iconGt {
        position: absolute;
        top: 12px;
        right: 85px;
        font-size: 22px;
        cursor: pointer;
        transition: 0.5s;
      }
      .iconEdit:hover {
        color: white;
      }
      .iconBin:hover {
        color: red;
      }
      .iconGt:hover {
        color: white;
      }
      button {
        height: 40px;
        width: 100%;
        background: rgba(0, 0, 0, 0.2);
        border: 2px solid rgba(203, 203, 203, 0.3);
        color: rgba(203, 203, 203, 1);
        border-radius: 5px;
        cursor: pointer;
        transition: 0.5s;
      }
      button:hover {
        background: rgba(0, 0, 0, 0.6);
        color: white;
        border: 2px solid rgba(203, 203, 203, 0.8);
      }
    }
  }

  @media (max-width: 800px) {
    max-width: 80%;
    .Header {
      .addIcon {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 30px;
        cursor: pointer;
        color: white;

        span {
          display: none;
          font-size: 20px;
          background: rgba(0, 0, 0, 0.3);
          padding: 5px 15px;
          border-radius: 13px;
          opacity: 60%;
          -webkit-animation: scale-in-hor-left 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        }
        &:hover {
          span {
            display: none;
          }
        }
      }
    }
    ul {
      width: 100%;
    }
  }
`;

export const StatusUser = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 14px;
  .online {
    width: 14px;
    height: 14px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const AdminPivo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  max-width: 90%;
  height: 100%;
  min-height: 85vh;
  margin-left: 70px;
  margin-bottom: 100px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 25px;
  .Header {
    display: flex;
    gap: 25px;
    align-items: center;
    h2 {
      font-size: 30px;
      color: white;
    }
    .addIcon {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 30px;
      cursor: pointer;
      color: white;
      .icons {
        position: relative;
        .pivoIcon {
          font-size: 40px;
        }
        .ADDIcon {
          position: absolute;
          left: 25px;
          top: 0px;
          background: white;
          border-radius: 50%;
          color: black;
          font-size: 25px;
        }
      }

      span {
        display: none;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.3);
        padding: 5px 15px;
        border-radius: 13px;
        opacity: 60%;
        -webkit-animation: scale-in-hor-left 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }
  ul {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 350px;
      background-color: rgba(222, 222, 222, 0.9);
      box-shadow: 0 3px 20px 0 rgba(31, 38, 135, 0.2);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      list-style: none;
      padding: 15px;
      max-width: 100%;
      .headerPivoInfo {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 90%;

        /* background: red; */
        input {
          padding: 5px 2px;
          font-size: 22px;
          font-weight: 600;
          border: none;
          background: none;
          /* background: blue; */
          max-width: 60%;
        }
        label {
          font-size: 25px;
        }
        .iconBin {
          position: absolute;
          right: 20px;
          top: 19px;
          font-size: 19px;
          cursor: pointer;
          transition: 0.5s;
          color: rgba(48, 48, 48, 1);
        }
        .iconBin:hover {
          color: rgba(150, 14, 14, 1);
        }
      }
      .infosPivo {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
        height: 47px;
        p {
          font-weight: 600;
        }
        label {
          font-weight: 600;
          color: rgba(62, 62, 62, 1);
          background: linear-gradient(
            to top,
            rgba(222, 222, 222, 1) 50%,
            transparent 50%
          );
          max-width: max-content;
          padding: 0px 4px;
          position: absolute;
          top: -11px;
          left: 15px;
          text-shadow: -1px -2px 8px rgba(255, 255, 255, 0.8);
        }
        input {
          padding: 15px 20px;
          border-radius: 15px;
          border: 1px solid black;
          background-color: rgba(222, 222, 222, 1);
          box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
        }
      }
      .selectBomb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        max-width: 100%;
        .select {
          width: 100px;
          max-width: 100%;
          overflow-x: auto;
        }
        button {
          width: 40px;
          min-width: 40px;
          height: 20px;
          border-radius: 50px;
          border: none;
          position: relative;
          cursor: pointer;
          background: white;
        }
      }
      .buttons {
        display: flex;
        gap: 5px;
        button {
          width: 100%;
          height: 30px;
          cursor: pointer;
        }
        .off {
          opacity: 60%;
          cursor: not-allowed;
        }
      }
    }
  }
  @media (max-width: 800px) {
    max-width: 80%;
  }
  @media (max-width: 600px) {
    .Header {
      .addIcon {
        span {
          display: none;
          font-size: 20px;
          background: rgba(0, 0, 0, 0.3);
          padding: 5px 15px;
          border-radius: 13px;
          opacity: 60%;
          -webkit-animation: scale-in-hor-left 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        }
        &:hover {
          span {
            display: none;
          }
        }
      }
    }
  }
`;

export const Dot = styled.span`
  position: absolute;
  background: rgba(0, 0, 0, 1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  transition: 0.5s;
  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 2px;
        `
      : css`
          left: 19px;
        `}
`;

export const AdminTanque = styled.main`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  background: red;
  height: 100%;
  min-height: 85vh;
  margin-left: 70px;
  margin-bottom: 100px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 25px;
  .Header {
    display: flex;
    gap: 25px;
    align-items: center;
    h2 {
      font-size: 30px;
      color: white;
    }
    .addIcon {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 30px;
      cursor: pointer;
      color: white;
      .icons {
        position: relative;
        img {
          width: 40px;
        }
        .pivoIcon {
          font-size: 40px;
        }
        .ADDIcon {
          position: absolute;
          left: 25px;
          top: 0px;
          background: white;
          border-radius: 50%;
          color: black;
          font-size: 28px;
          opacity: 80%;
        }
      }

      span {
        display: none;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.3);
        padding: 5px 15px;
        border-radius: 13px;
        opacity: 60%;
        -webkit-animation: scale-in-hor-left 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
      &:hover {
        span {
          display: inline;
        }
      }
    }
  }
  ul {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    li {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 350px;
      background-color: rgba(222, 222, 222, 0.9);
      box-shadow: 0 3px 20px 0 rgba(31, 38, 135, 0.2);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      list-style: none;
      padding: 15px;
      max-width: 100%;
      .headerTanqueInfo {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 90%;

        /* background: red; */
        input {
          padding: 5px 2px;
          font-size: 22px;
          font-weight: 600;
          border: none;
          background: none;
          /* background: blue; */
          max-width: 60%;
        }
        label {
          font-size: 25px;
        }
        .iconBin {
          position: absolute;
          right: 20px;
          top: 19px;
          font-size: 19px;
          cursor: pointer;
          transition: 0.5s;
          color: rgba(48, 48, 48, 1);
        }
        .iconBin:hover {
          color: rgba(150, 14, 14, 1);
        }
      }
      .infosPivo {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
        height: 47px;
        /* background: red; */
        p {
          font-weight: 600;
        }
        label {
          font-weight: 600;
          color: rgba(62, 62, 62, 1);
          background: linear-gradient(
            to top,
            rgba(222, 222, 222, 1) 50%,
            transparent 50%
          );
          max-width: max-content;
          padding: 0px 4px;
          position: absolute;
          top: -9.9px;
          left: 15px;
          text-shadow: -1px -2px 8px rgba(255, 255, 255, 0.8);
        }
        input {
          padding: 15px 20px;
          border-radius: 15px;
          border: 1px solid black;
          background-color: rgba(222, 222, 222, 1);
          box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px -14px 55px -4px rgba(0, 0, 0, 0.3);
        }
        .selectBomb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          max-width: 100%;
          .select {
            width: 100px;
            max-width: 100%;
            overflow-x: auto;
          }
          button {
            width: 40px;
            min-width: 40px;
            height: 20px;
            border-radius: 50px;
            border: none;
            position: relative;
            cursor: pointer;
            background: white;
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: center;
        gap: 8px;
        button {
          width: 100%;
          height: 30px;
          cursor: pointer;
          border-radius: 5px;
          border: 1px solid rgba(39, 39, 39, 0.8);
          transition: 0.5s;
        }
        .bombsSelect {
          border-radius: 10px;
          font-size: 15px;
          width: 32%;
        }
        .off {
          opacity: 60%;
          cursor: not-allowed;
        }
      }
      button:hover {
        background-color: white;
      }
    }
  }
`;
