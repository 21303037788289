import React, { useContext, useEffect, useState } from "react";
import { PrevisaoStyled } from "../../styles/meteorologia/previsaoTempo";
import { ClimaContext } from "../../contexts/contexClima";
import { ClimaAtualStyled } from "../../styles/meteorologia/tempoAtual";
import { apiConnect } from "../../services/api";

const diasDaSemana = [
  { nome: "Seg.", chave: "previsaoSegunda" },
  { nome: "Ter.", chave: "previsaoTerca" },
  { nome: "Qua.", chave: "previsaoQuarta" },
  { nome: "Qui.", chave: "previsaoQuinta" },
  { nome: "Sex.", chave: "previsaoSexta" },
  { nome: "Sáb.", chave: "previsaoSabado" },
  { nome: "Dom.", chave: "previsaoDomingo" },
];

const obterIndiceDiaAtual = () => {
  const hoje = new Date().getDay(); // 0 (Dom) a 6 (Sáb)
  return hoje === 0 ? 6 : hoje - 1; // Ajusta para Segunda-feira como 0
};

export const PrevisaoDoTempo = () => {
  const { climaIdSelect, selecionarImagemClima, climaAtual } =
    useContext(ClimaContext);

  const [listPrevisoesClima, setListPrevisoesClima] = useState(null);
  const [tempPorDiaMin, setTempPorDiaMin] = useState({});
  const [tempPorDiaMax, setTempPorDiaMax] = useState({});
  const [descricaoMaisComumPorDia, setDescricaoMaisComumPorDia] = useState({});

  const listPrevisoes = async (idSelect) => {
    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.get(
        `/meteorologia/previsao/Leitura/${idSelect}`,
        headerApi
      );

      setListPrevisoesClima(response.data);
    } catch (error) {
      console.log(error);
      setListPrevisoesClima([]);
    }
  };

  const calcularDescricaoMaisComumPorDia = (previsoes) => {
    const diasDaSemana = [
      "previsaoSegunda",
      "previsaoTerca",
      "previsaoQuarta",
      "previsaoQuinta",
      "previsaoSexta",
      "previsaoSabado",
      "previsaoDomingo",
    ];

    const descricaoMaisComum = {};

    diasDaSemana.forEach((dia) => {
      const previsoesDia = previsoes[dia] || [];
      const descricaoCount = {};

      previsoesDia.forEach((previsao) => {
        const descricao = previsao.description;
        descricaoCount[descricao] = (descricaoCount[descricao] || 0) + 1;
      });

      const descricaoMaisComumDia = Object.entries(descricaoCount).reduce(
        (acc, [descricao, frequencia]) =>
          frequencia > acc.frequencia ? { descricao, frequencia } : acc,
        { descricao: "", frequencia: 0 }
      ).descricao;

      descricaoMaisComum[dia] = descricaoMaisComumDia;
    });

    return descricaoMaisComum;
  };
  useEffect(() => {
    if (climaIdSelect) {
      listPrevisoes(climaIdSelect);
    }
  }, []);

  useEffect(() => {
    if (climaIdSelect) {
      listPrevisoes(climaIdSelect);
    }
  }, [climaIdSelect]);
  
  const obterDiasValidos = () => {
    const indiceDiaAtual = obterIndiceDiaAtual();
    const diasValidos = [
      ...diasDaSemana.slice(indiceDiaAtual),
      ...diasDaSemana.slice(0, indiceDiaAtual),
    ].slice(0, 5);

    return diasValidos;
  };

  useEffect(() => {
    if (listPrevisoesClima) {
      const tempPorDiaAtualizadoMin = {};
      const tempPorDiaMaxAtualizado = {};
      const descricaoPorDia =
        calcularDescricaoMaisComumPorDia(listPrevisoesClima);

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturas =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_min) || [];
        tempPorDiaAtualizadoMin[dia] = temperaturas;
      });

      Object.keys(listPrevisoesClima).forEach((dia) => {
        const temperaturasMax =
          listPrevisoesClima[dia]?.map((previsao) => previsao.temp_max) || [];
        tempPorDiaMaxAtualizado[dia] = temperaturasMax;
      });

      setTempPorDiaMin(tempPorDiaAtualizadoMin);
      setTempPorDiaMax(tempPorDiaMaxAtualizado);
      setDescricaoMaisComumPorDia(descricaoPorDia);
    }
  }, [listPrevisoesClima]);

  if (!climaAtual) {
    return (
      <ClimaAtualStyled>
        <div>Carregando previsão do tempo...</div>;
      </ClimaAtualStyled>
    );
  }
  const diaAtualChave = obterDiasValidos()[0]?.chave;

  const previsaoDoDia =
    listPrevisoesClima && diaAtualChave
      ? listPrevisoesClima[diaAtualChave]
      : [];

  return (
    <PrevisaoStyled>
      <div className="tituloPrevisao">
        <h2>Previsão do Tempo</h2>
      </div>
      <ul className="diasSemana">
        {obterDiasValidos().map((dia) => {
          return (
            <li className="dia" key={dia.chave}>
              <div>
                <span>Max. </span>
                <span className="tempPre">
                  {tempPorDiaMax[dia.chave]?.length > 0 &&
                    Math.max(...tempPorDiaMax[dia.chave]).toFixed(0)}
                  °
                </span>
              </div>
              <span className="Status">
                {" "}
                {descricaoMaisComumPorDia[dia.chave]}
              </span>
              <img
                src={selecionarImagemClima(
                  descricaoMaisComumPorDia[dia.chave] || ""
                )}
                alt={dia.nome}
              />
              <div>
                <span>Min. </span>
                <span className="tempPre">
                  {tempPorDiaMin[dia.chave]?.length > 0 &&
                    Math.min(...tempPorDiaMin[dia.chave]).toFixed(0)}
                  °
                </span>
              </div>
              <span className="diaSemana">{dia.nome}</span>
            </li>
          );
        })}
      </ul>
      <ul className="previsaoDoDia">
        {climaAtual && (
          <li className="nowPrev">
            <span>Agora</span>
            <span className="icon">
              <img
                src={selecionarImagemClima(climaAtual.description || "")}
                alt={climaAtual.description}
              />
            </span>
            <span>{climaAtual.temp.toFixed(0)}°c</span>
          </li>
        )}
        {previsaoDoDia && previsaoDoDia.length > 0 ? (
          previsaoDoDia
            .sort((a, b) => {
              // Transformar forecast_data em números para comparação
              const [aHours, aMinutes] = a.forecast_data.split(":").map(Number);
              const [bHours, bMinutes] = b.forecast_data.split(":").map(Number);

              // Comparar as horas e minutos
              return aHours !== bHours ? aHours - bHours : aMinutes - bMinutes;
            })
            .map((tempoPrev) => (
              <li key={tempoPrev.id} className="diaPrevs">
                <span>{tempoPrev.forecast_data}</span>
                <span className="icon">
                  <img
                    src={selecionarImagemClima(tempoPrev.description || "")}
                    alt={tempoPrev.description}
                  />
                </span>
                <span>{tempoPrev.temp.toFixed(0)}°</span>
              </li>
            ))
        ) : (
          <li>Nenhuma previsão disponível</li>
        )}
      </ul>
    </PrevisaoStyled>
  );
};
