import { useContext, useState } from "react";
import { AdminContext } from "../../contexts/contexAdmin";
import { AdminPivo, Dot } from "../../styles/admin";
import { IoAddCircle } from "react-icons/io5";
import { SiPivotaltracker } from "react-icons/si";
import { ImBin } from "react-icons/im";
import { CiEdit } from "react-icons/ci";
import { useEffect } from "react";
import { apiConnect } from "../../services/api";

export const PivoAdmin = () => {
  const {
    pivosList,
    modalPivoConfirmation,
    setModalPivoConfirmation,
    setSalvePivoInfo,
    modalPivoDeletConfirmation,
    setModalPivoDeletConfirmation,
    setSavePivoId,
    modalCreatingPivo,
    setModalCreatingPivo,
  } = useContext(AdminContext);

  const [editedPivos, setEditedPivos] = useState({});
  const [loading, setloading] = useState(false);
  const [Unidades, setUnidades] = useState([]);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");
      const idUnidade = sessionStorage.getItem("connectionsUnitId");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get(`unidade/leituratodos`, headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  // Atualiza o estado de editedPivos quando a lista de pivôs é alterada
  useEffect(() => {
    const initialEditedPivos = {};
    pivosList.forEach((pivo) => {
      initialEditedPivos[pivo.id] = { ...pivo }; // supondo que cada pivo tenha um campo de identificação único chamado 'id'
    });
    setEditedPivos(initialEditedPivos);
  }, [pivosList]);

  const handleInputChange = (e, pivoId, type) => {
    setEditedPivos((prevEditedPivos) => ({
      ...prevEditedPivos,
      [pivoId]: {
        ...prevEditedPivos[pivoId],
        [type]: e,
      },
    }));
  };

  useEffect(() => {
    ListUnidades();
  }, []);

  return (
    <AdminPivo>
      <div className="Header">
        <h2>Pivôs</h2>
        <div className="addIcon">
          <div
            className="icons"
            onClick={() => setModalCreatingPivo(!modalCreatingPivo)}
          >
            <SiPivotaltracker className="pivoIcon" />
            <IoAddCircle className="ADDIcon" />
          </div>
          <span>Criar Um Novo Pivô</span>
        </div>
      </div>
      <ul>
        {pivosList.map((pivo) => {
          const cancelChanges = () => {
            setEditedPivos((prevEditedPivos) => ({
              ...prevEditedPivos,
              [pivo.id]: {
                ...prevEditedPivos[pivo.id],
                name: pivo.name,
                longitude: pivo.longitude,
                latitude: pivo.latitude,
                tamanho: pivo.tamanho,
                tipo_bomba: pivo.tipo_bomba,
                status: pivo.status,
              },
            }));
          };

          const nameUnidade = Unidades.find((e) => e.id === pivo.unidade.id);
          const checkChangesPivo =
            JSON.stringify(pivo) === JSON.stringify(editedPivos[pivo.id]);

          return (
            <li>
              <div className="headerPivoInfo">
                <input
                  type="text"
                  id={`${pivo.id}name`}
                  value={editedPivos[pivo.id]?.name || ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, pivo.id, "name")
                  }
                />
                <label htmlFor={`${pivo.id}name`}>
                  <CiEdit />
                </label>
                <ImBin
                  className="iconBin"
                  onClick={() => {
                    setModalPivoDeletConfirmation(!modalPivoDeletConfirmation);
                    setSavePivoId(pivo.id);
                  }}
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${pivo.id}latitude`}>Latitude</label>
                <input
                  type="number"
                  id={`${pivo.id}latitude`}
                  value={editedPivos[pivo.id]?.latitude || ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, pivo.id, "latitude")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor="longitude">Longitude</label>
                <input
                  type="number"
                  id={`${pivo.id}longitude`}
                  value={editedPivos[pivo.id]?.longitude || ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, pivo.id, "longitude")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${pivo.id}tamanho`}>Diâmetro do Pivô</label>
                <input
                  type="number"
                  id={`${pivo.id}tamanho`}
                  value={editedPivos[pivo.id]?.tamanho || ""}
                  onChange={(e) =>
                    handleInputChange(e.target.value, pivo.id, "tamanho")
                  }
                />
              </div>
              <div className="infosPivo">
                <label htmlFor={`${pivo.id}unidade`}>Unidade</label>
                <input
                  type="text"
                  id={`${pivo.id}unidade`}
                  disabled={true}
                  value={nameUnidade ? nameUnidade.name : ""}
                />
              </div>
              <div className="infosPivo">
                <p>Status do Pivô</p>
                <div className="selectBomb">
                  <span className="select">Ativado</span>
                  <button
                    onClick={() =>
                      handleInputChange(
                        !editedPivos[pivo.id].status,
                        pivo.id,
                        "status"
                      )
                    }
                  >
                    <Dot isLeft={editedPivos[pivo.id]?.status} />
                  </button>
                  <span className="select">Desativado</span>
                </div>
              </div>
              <div className="infosPivo">
                <p>Tipo de Bomba</p>
                <div className="selectBomb">
                  <span className="select">Eletrobomba</span>
                  <button
                    onClick={() =>
                      handleInputChange(
                        !editedPivos[pivo.id].tipo_bomba,
                        pivo.id,
                        "tipo_bomba"
                      )
                    }
                  >
                    <Dot isLeft={editedPivos[pivo.id]?.tipo_bomba} />
                  </button>
                  <span className="select">Motobomba</span>
                </div>
              </div>
              <div className="buttons">
                <button
                  className={checkChangesPivo ? "off" : ""}
                  disabled={checkChangesPivo}
                  onClick={() => {
                    setModalPivoConfirmation(!modalPivoConfirmation);
                    setSalvePivoInfo([pivo.id, editedPivos[pivo.id]]);
                  }}
                >
                  Salvar
                </button>
                <button
                  disabled={checkChangesPivo}
                  onClick={() => cancelChanges()}
                >
                  Cancelar
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </AdminPivo>
  );
};
