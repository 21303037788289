import styled from "styled-components";

export const LoadingSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  gap: 30px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.6);
  backdrop-filter: blur(6px);
  z-index: 999;
  img {
    position: absolute;
    width: 80px;
    z-index: 2;
    margin-bottom: 58px;
  }
  h3 {
    font-size: 30px;
    color: #fff;
  }
  .loaderSquare {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: relative;
    border: 4px solid #fff;
    animation: loader 2s infinite ease;
    box-shadow: 0px -1px 14px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px -1px 14px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px -1px 14px 0px rgba(255, 255, 255, 0.75);
  }

  .loaderSquare-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
`;
