import styled from "styled-components";

export const GraficosTankes = styled.div`
  width: 100%;
  height: 99%;
  position: relative;
  .conteinerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    color: rgba(141, 141, 141, 1);
    font-size: 45px;
    right: 6%;
    top: 0px;
    span {
      font-size: 18px;
      position: absolute;
      top: 17px;
      left: 12px;
    }
  }
  h2 {
    color: rgba(199, 199, 199, 1);
  }
  .headerGrafic {
    display: flex;
    gap: 15px;
    .neveisButton {
      margin: 6px;
      display: flex;
      gap: 5px;
      span {
        color: rgba(187, 187, 187, 1);
      }
      button {
        cursor: pointer;
        width: 30px;
        height: 15px;
        background-color: rgba(215, 215, 215, 0.6);
        transition: 0.5s;
      }
      button:hover {
        background-color: rgba(215, 215, 215, 1);
      }
    }
  }
  .not404 {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    color: rgba(255, 255, 255, 1);
  }
  .ListDate {
    position: absolute;
    right: 11%;
    top: 5px;
    select {
      background-color: rgba(0, 0, 0, 0.3);
      color: rgba(204, 204, 204, 1);
      width: 70px;
      height: 35px;
      border-radius: 5px;
      border: 2px solid rgba(121, 121, 121, 1);
      padding-left: 7px;
    }
  }
  .calendarDiv {
    display: flex;
    align-items: center;
    z-index: 9;
    padding-top: 50px;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.45) 70%,
      rgba(0, 0, 0, 0) 100%
    );
    .closeModal {
      position: absolute;
      right: 32%;
      font-size: 30px;
      cursor: pointer;
      color: rgba(199, 198, 198, 1);
      transition: 0.5s;
    }
    .closeModal:hover {
      color: white;
    }
    h3 {
      font-size: 25px;
      text-shadow: 3px 1px 5px rgba(0, 0, 0, 0.64);
      color: rgba(212, 212, 212, 1);
    }
    .calendar {
      border-radius: 20px;
      overflow: auto;
      /* background-color: rgba(35, 35, 35, 0.82); */
      .react-calendar__navigation button {
        background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo do botão */
        color: white; /* Cor do texto */
        border: none; /* Removendo a borda padrão */
      }
    }
    .buutons {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 350px;
      max-width: 100%;
      button {
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }
`;
