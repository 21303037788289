import { HeaderMobilePivo, HeaderStyled2 } from "../../styles/header";
import { IoHome, IoExit, IoNotifications } from "react-icons/io5";
import imgLogo from "../../assets/logos/novaLogoAcls.png";
import { useNavigate } from "react-router-dom";
import { RiAdminFill } from "react-icons/ri";
import { useState } from "react";
import { HomeContext } from "../../contexts/contexHome";
import { useContext } from "react";
import { SiAlwaysdata } from "react-icons/si";
import { AiTwotoneControl, AiOutlineSchedule } from "react-icons/ai";
import { PivoContext } from "../../contexts/contexPivos";
import { BsPinMap } from "react-icons/bs";
import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";
import { FaGears } from "react-icons/fa6";

export const HeaderPivo = () => {
  const { user } = useContext(HomeContext);
  const navigate = useNavigate();
  const [openHeader, setOpenHeader] = useState(false);
  const {
    setPivoPage,
    pivoPage,
    modalAlertaOn,
    setModalAlertaOn,
    alertsConts,
  } = useContext(PivoContext);

  const unityExists = sessionStorage.getItem("connectionsUnit");

  return (
    <HeaderStyled2 open={openHeader}>
      <ul>
        <li
          className="logoLi"
          onClick={() => {
            setOpenHeader(!openHeader);
          }}
        >
          <img src={imgLogo} alt="" />
        </li>
        <li
          onClick={() => {
            navigate("/");
          }}
        >
          <IoHome className="iconHome" />
          <span>Home</span>
        </li>
        <li
          className={`${pivoPage === "Map" ? "select" : ""}`}
          onClick={() => {
            navigate(`/${unityExists}/Painelpivos/Map`);
            setPivoPage("Map");
          }}
        >
          <BsPinMap />
          <span>Mapeamento</span>
        </li>
        <li
          className={`${pivoPage === "Historico" ? "select" : ""}`}
          onClick={() => {
            navigate(`/${unityExists}/Painelpivos/Historico`);
            setPivoPage("Historico");
          }}
        >
          <SiAlwaysdata />
          <span>Histórico</span>
        </li>
        <li
          className={`${pivoPage === "Controler" ? "select" : ""}`}
          onClick={() => {
            navigate(`/${unityExists}/Painelpivos/Controler`);
            setPivoPage("Controler");
          }}
        >
          <AiTwotoneControl />
          <span>Controle</span>
        </li>
        <li
          className={`${pivoPage === "Agenda" ? "select" : ""}`}
          onClick={() => {
            navigate(`/${unityExists}/Painelpivos/Agenda`);
            setPivoPage("Agenda");
          }}
        >
          <AiOutlineSchedule />
          <span>Agendamentos</span>
        </li>
        <li
          className={`${pivoPage === "Configuracao" ? "select" : ""}`}
          onClick={() => {
            navigate(`/${unityExists}/Painelpivos/Configuracao`);
            setPivoPage("Configuracao");
          }}
        >
          <FaGears />
          <span>Configuração</span>
        </li>
        <li
          className={`${pivoPage === "Alertas" ? "select" : ""}`}
          onClick={() => setModalAlertaOn(!modalAlertaOn)}
        >
          {alertsConts && alertsConts.count > 0 && (
            <div className="alerts">{alertsConts.count}</div>
          )}
          <IoNotifications />
          <span>Alertas</span>
        </li>
      </ul>
      <div className="sectionBottom">
        {user.is_superuser ? (
          <div className="admin">
            <RiAdminFill
              onClick={() => {
                navigate("/admin");
              }}
            />
            <span>Admin</span>
          </div>
        ) : null}

        <div className="exit">
          <IoExit
            onClick={() => {
              sessionStorage.removeItem("connectionsToken");
              sessionStorage.removeItem("connectionsUnit");
              navigate("/Login");
            }}
          />
          <span>Sair</span>
        </div>
      </div>
    </HeaderStyled2>
  );
};

export const HeaderPivoMobile = () => {
  const { user } = useContext(HomeContext);
  const navigate = useNavigate();
  const {
    setPivoPage,
    pivoPage,
    modalAlertaOn,
    setModalAlertaOn,
    alertsConts,
  } = useContext(PivoContext);

  const [barControler, setBarControler] = useState(false);

  const unityExists = sessionStorage.getItem("connectionsUnit");

  return (
    <HeaderMobilePivo>
      {barControler && (
        <section className="additionalSection">
          <ul>
            <li
              className={`${pivoPage === "Historico" ? "select" : ""}`}
              onClick={() => {
                setBarControler(false);
                setPivoPage("Historico");
                navigate(`/${unityExists}/Painelpivos/Historico`);
              }}
            >
              <SiAlwaysdata />
            </li>
            <li
              className={`${pivoPage === "Agenda" ? "select" : ""}`}
              onClick={() => {
                setBarControler(false);
                setPivoPage("Agenda");
                navigate(`/${unityExists}/Painelpivos/Agenda`);
              }}
            >
              <AiOutlineSchedule />
            </li>
            <li
              className={`${pivoPage === "Configuracao" ? "select" : ""}`}
              onClick={() => {
                navigate(`/${unityExists}/Painelpivos/Configuracao`);
                setPivoPage("Configuracao");
                setBarControler(false);
              }}
            >
              <FaGears />
            </li>
          </ul>
          <div className="sectionBottom">
            {user.is_superuser ? (
              <div className="admin">
                <RiAdminFill
                  onClick={() => {
                    navigate("/admin");
                  }}
                />
                <span>Admin</span>
              </div>
            ) : null}
          </div>
        </section>
      )}
      <section className="fistSection">
        <ul>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            <IoHome className="iconHome" />
          </li>
          <li
            className={`${pivoPage === "Map" ? "select" : ""}`}
            onClick={() => {
              navigate(`/${unityExists}/Painelpivos/Map`);
              setPivoPage("Map");
              setBarControler(false);
            }}
          >
            <BsPinMap />
          </li>
          <li
            className={`${pivoPage === "Controler" ? "select" : ""}`}
            onClick={() => {
              setBarControler(false);
              setPivoPage("Controler");
            }}
          >
            <AiTwotoneControl />
          </li>
          <li
            className={`${pivoPage === "Alertas" ? "select" : ""}`}
            onClick={() => {
              setBarControler(false);
              setModalAlertaOn(!modalAlertaOn);
            }}
          >
            {alertsConts && alertsConts.count > 0 && (
              <div className="alerts">{alertsConts.count}</div>
            )}
            <IoNotifications />
          </li>
        </ul>
        <div className="sectionBottom">
          {barControler ? (
            <IoIosArrowDropdown
              onClick={() => setBarControler(!barControler)}
            />
          ) : (
            <IoIosArrowDropup onClick={() => setBarControler(!barControler)} />
          )}
          <div className="exit">
            <IoExit
              onClick={() => {
                sessionStorage.removeItem("connectionsToken");
                sessionStorage.removeItem("connectionsUnit");
                navigate("/Login");
              }}
            />
            <span>Sair</span>
          </div>
        </div>
      </section>
    </HeaderMobilePivo>
  );
};
