import styled from "styled-components";

export const ComboGraficosStyled = styled.div`
  padding: 15px;
  .headerGraficos {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    .botao {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: var(--bgBotao);
      color: var(--branco);
      /* border: none; */
      font-size: 30px;
      border-radius: 10px;
      cursor: pointer;
    }
    .botao:hover {
      border: 1px solid var(--branco);
    }
    .titulo {
      display: flex;
      align-items: center;
      font-size: 18px;
      gap: 10px;

      img {
        gap: 10px;
        width: 40px;
      }
    }
    .calendario {
      width: 40px;
      height: 40px;
    }
  }
  .graficos {
    width: 100%;
    height: 33vh;
    background-color: var(--bgGrafico);
    border-radius: 15px;
  }
`;

export const GraficoComboStyled = styled.div`
  width: 100%;
  height: 85%;
  max-height: 100%;

  .recharts-cartesian-axis-tick-value {
    fill: #ffffff;
  }

  .recharts-legend-item-text {
    color: #ffffff;
  }

  .recharts-tooltip-label,
  .recharts-tooltip-item,
  .recharts-tooltip-item-list {
    color: #ffffff;
  }

  .recharts-label {
    fill: #ffffff;
  }

  .recharts-xaxis .recharts-cartesian-axis-tick,
  .recharts-yaxis .recharts-cartesian-axis-tick {
    fill: #ffffff;
  }
`;
