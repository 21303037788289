import styled from "styled-components";

export const ModlaStatusStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 99;
  .conteiner {
    width: 40%;
    background: rgba(197, 197, 197, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    .fecharModal {
      position: absolute;
      top: 10px;
      right: 25px;
      font-size: 30px;
      cursor: pointer;
    }
    ul {
      list-style: none;
      padding: 20px;
      width: 100%;
      gap: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      li {
        width: 60%;
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 20px;
            font-weight: 600;
            color: rgba(67, 67, 67, 1);
            text-align: center;

            width: 100%;
          }
        }
      }
    }
  }
`;

export const ModalAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 10;
  max-width: 100%;
  .conteiner {
    width: 90%;
    height: 90%;
    background: rgba(158, 155, 156, 1);
    border-radius: 15px;
    padding: 50px 35px;
    position: relative;
    max-width: 100%;
    .closeBotao {
      position: absolute;
      font-size: 30px;
      right: 15px;
      top: 10px;
      cursor: pointer;
      color: rgba(102, 102, 102, 1);
      transition: 0.5s;
    }
    .closeBotao:hover {
      color: rgba(47, 47, 47, 1);
    }
    .headerModal {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 20px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
      margin-bottom: 25px;
      span {
        width: 200px;
        text-align: center;
        max-width: 100%;
      }
    }
    ul {
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      scrollbar-width: thin;
      scrollbar-color: rgba(100, 100, 100, 0.8) rgba(100, 100, 100, 0);
      li {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        border-bottom: 2px solid rgba(87, 87, 87, 1);
        span {
          width: 200px;
          text-align: center;
          max-width: 100%;
        }
      }
    }
  }
`;

export const ModalConfirmController = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.3);
  z-index: 99;
  .deleteContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    max-width: 80%;
    background-color: rgba(18, 18, 20, 0.9);
    border: 2px solid #b2b2b2;
    padding: 20px;
    border-radius: 15px;
    gap: 15px;
    h2 {
      color: white;
    }
    div {
      display: flex;
      gap: 15px;
      width: 100%;
      button {
        width: 100%;
        height: 50px;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
        color: #ffffff;
        border: none;
        background-color: rgba(195, 13, 26, 1);
        border-radius: 8px;
        cursor: pointer;
      }
      .buttonYes {
        background-color: transparent;
        color: rgba(1, 255, 24, 1);
        border: 2px solid rgba(1, 255, 24, 1);
        transition: 1s;
      }
      .buttonYes:hover {
        background-color: rgba(5, 164, 21, 1);
        color: white;
      }
    }
  }
`;

export const ModalValulaStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(18, 18, 20, 0.5);
  z-index: 99;
  .conteiner {
    display: flex;
    background-color: rgba(209, 209, 209, 0.95);
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    .range {
      display: flex;
      gap: 10px;
      width: 70%;
      align-items: center;
      input {
        -webkit-appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background: rgba(74, 74, 74, 1);
        outline: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: rgba(103, 103, 103, 1);
          cursor: pointer;
        }
      }
      span {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .buttons {
      display: flex;
      gap: 15px;
      button {
        width: 200px;
        height: 35px;
        cursor: pointer;
        border-radius: 5px;
        border: 2px solid rgba(87, 87, 87, 1);
        font-weight: 600;
      }
      .cancel {
        background-color: rgba(87, 87, 87, 1);
        color: rgba(191, 191, 191, 1);
      }
      .cancel:hover {
        border: 2px;
        border: 2px solid rgba(180, 180, 180, 1);
      }
    }
  }
`;
