import React, { useContext, useRef, useState, useEffect } from "react";
import { AdminContext } from "../../contexts/contexAdmin";
import { FaUserAlt } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";
import imgPump from "../../assets/icons/pumpWite.png";
import imgMotobomb from "../../assets/icons/motobombaWhite.png";
import imgTanque from "../../assets/icons/tanquesWhite.png";
import { AdminPage } from "../../styles/admin";
import { Footer } from "../../components/footer";
import { Header2 } from "../../components/header2";
import { AdminUser } from "./adminUser";
import imgConstruction from "../../assets/icons/pikachu.gif";
import { PivoAdmin } from "./pivoAdmin";
import { FaCloudSun, FaCow } from "react-icons/fa6";
import {
  BombasTanques,
  CreatingPivo,
  CreatingTanque,
  CreatingUser,
  MedidoresTanques,
  ModalConfirmChangesPivo,
  ModalConfirmChangesTanque,
  ModalConfirmDeleteUser,
  ModalConfirmPivoDeletion,
  ModalUpdatePassword,
  ModalUpdateUser,
} from "./modalAdmin";
import { SquareLoading } from "../../components/loaders";
import { TanqueAdmin } from "./tanqueAdmin";
import imgTanque2 from "../../assets/icons/Novotanque.png";

export const PageAdmin = () => {
  const {
    adminPage,
    setAdminPage,
    modalPivoConfirmation,
    modalPivoDeletConfirmation,
    modalCreatingPivo,
    modalCreatingUser,
    modalUserDeletConfirmation,
    modaUpdatePassword,
    modalEditUser,
    loading,
    modalConfimation,
    modalMedidores,
    medidoresList,
    modalBombaTanque,
    modalCreatTanque,
    setModalCreatTanque,
  } = useContext(AdminContext);
  const [activeTab, setActiveTab] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState({
    left: 0,
    width: 0,
  });
  const indicatorRef = useRef(null);

  useEffect(() => {
    if (activeTab !== null && indicatorRef.current) {
      const activeElement = indicatorRef.current.children[activeTab];
      setIndicatorPosition({
        left: activeElement.offsetLeft,
        width: activeElement.offsetWidth,
      });
    }
  }, [activeTab]);

  const handleTabClick = (index, pageName) => {
    setActiveTab(index);
    setAdminPage(pageName);
  };

  return (
    <AdminPage>
      <Header2 />
      {loading && <SquareLoading />}
      {modalPivoConfirmation && <ModalConfirmChangesPivo />}
      {modalPivoDeletConfirmation && <ModalConfirmPivoDeletion />}
      {modalCreatingPivo && <CreatingPivo />}
      {modalCreatingUser && <CreatingUser />}
      {modalUserDeletConfirmation && <ModalConfirmDeleteUser />}
      {modaUpdatePassword && <ModalUpdatePassword />}
      {modalEditUser && <ModalUpdateUser />}
      {modalConfimation && <ModalConfirmChangesTanque />}
      {modalMedidores && <MedidoresTanques tanqueInfo={medidoresList} />}
      {modalBombaTanque && <BombasTanques tanqueInfo={medidoresList} />}
      {modalCreatTanque && <CreatingTanque />}

      <nav>
        <ul className="nav-list" ref={indicatorRef}>
          <li
            onClick={() => handleTabClick(0, "User")}
            className={`nav-item ${adminPage === "User" ? "active" : ""}`}
          >
            <FaUserAlt />
          </li>
          <li
            onClick={() => handleTabClick(1, "Pivo")}
            className={`nav-item ${adminPage === "Pivo" ? "active" : ""}`}
          >
            <SiPivotaltracker />
          </li>
          <li
            onClick={() => handleTabClick(2, "Tanque")}
            className={`nav-item ${adminPage === "Tanque" ? "active" : ""}`}
          >
            <img src={imgTanque2} alt="" />
          </li>
          <li
            onClick={() => handleTabClick(3, "Clima")}
            className={`nav-item ${adminPage === "Clima" ? "active" : ""}`}
          >
            <FaCloudSun />
          </li>
          <li
            onClick={() => handleTabClick(4, "EletroBomba")}
            className={`nav-item ${
              adminPage === "EletroBomba" ? "active" : ""
            }`}
          >
            <img src={imgPump} alt="" />
          </li>
          <li
            // onClick={() => handleTabClick(4, "MotoBomba")}
            className={`nav-item disabled ${
              adminPage === "MotoBomba" ? "active" : ""
            }`}
          >
            <img src={imgMotobomb} alt="" />
          </li>
          <li className="nav-item disabled">
            <FaCow />
          </li>
        </ul>
        <div className="nav-indicator" style={indicatorPosition}></div>
      </nav>
      {adminPage === "User" ? <AdminUser /> : null}
      {adminPage === "Pivo" ? <PivoAdmin /> : null}
      {adminPage === "Tanque" ? <TanqueAdmin /> : null}
      {adminPage === "Clima" ? (
        <div className="construction">
          <img src={imgConstruction} alt="" />
        </div>
      ) : null}
      {adminPage === "EletroBomba" ? (
        <div className="construction">
          <img src={imgConstruction} alt="" />
        </div>
      ) : null}
      <Footer relative={"true"} />
    </AdminPage>
  );
};
